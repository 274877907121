import React from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import type { User } from 'firebase/auth';
import { FooterIconLink } from './FooterIconLink';
import { app_links } from '~/lib/app_links';
import { useI18nLn } from '~/hooks/store';

type Props = {
  isMobileScreen: boolean;
  user?: User;
};

export const FooterBeforeCaution: React.FC<Props> = (props: Props) => {
  const { dictionary } = useI18nLn();

  if (dictionary == null) return null;
  if (props.isMobileScreen) {
    return (
      <Flex
        flexDir="column"
        w="full"
        bgColor="white"
        justify="left"
        align="baseline"
      >
        <Box h="16px" />
        <Text fontSize="11px" lineHeight="18px" textColor="desc" px="16px">
          {dictionary.guide.subtitle}
        </Text>
        <Text
          fontSize="19px"
          lineHeight="32px"
          fontWeight="bold"
          letterSpacing="-3%"
          px="16px"
        >
          {dictionary.guide.title}
        </Text>
        <Box h="4px" />
        <FooterIconLink
          icon="/assets/icon/webroll.svg"
          title={dictionary.guide.what_is_service}
          link={app_links.webroll}
          isMobileScreen={props.isMobileScreen}
        />
        <FooterIconLink
          icon="/assets/icon/delivery.svg"
          title={dictionary.guide.delivery}
          link={app_links.delivery}
          isMobileScreen={props.isMobileScreen}
        />
        <FooterIconLink
          icon="/assets/icon/question.svg"
          title={dictionary.guide.qa}
          link={app_links.question}
          isMobileScreen={props.isMobileScreen}
        />
        <FooterIconLink
          icon="/assets/icon/contact.svg"
          title={dictionary.guide.contact}
          link={
            props.user?.isAnonymous ?? true
              ? 'https://docs.google.com/forms/d/e/1FAIpQLSenvL-3jMbOgHgOXp4KUk9b4vVhLPFOky1FD6Dlnbz86LCigQ/viewform?usp=sf_link'
              : `https://docs.google.com/forms/d/e/1FAIpQLSeiVgMABhJ1P2-4bGyLGB0mI7fEj0zSKdXtORXDCUw7hz7SkQ/viewform?usp=pp_url&entry.828252361=${props.user?.uid}`
          }
          last={true}
          isMobileScreen={props.isMobileScreen}
        />
        <Box h="4px" />
      </Flex>
    );
  } else {
    return (
      <Flex
        flexDir="column"
        w="full"
        bgColor="white"
        justify="center"
        alignContent="center"
        alignItems="center"
        align="baseline"
      >
        <Box h="16px" />
        <Text fontSize="11px" lineHeight="18px" textColor="desc" px="16px">
          {dictionary.guide.subtitle}
        </Text>
        <Text
          fontSize="19px"
          lineHeight="32px"
          fontWeight="bold"
          letterSpacing="-3%"
          px="16px"
        >
          {dictionary.guide.title}
        </Text>
        <Box h="16px" />
        <Flex flexDir="row" w="600px" px="8px">
          <FooterIconLink
            icon="/assets/icon/webroll_big.svg"
            title={dictionary.guide.what_is_service}
            link={app_links.webroll}
            isMobileScreen={props.isMobileScreen}
          />
          <Box w="8px" />
          <FooterIconLink
            icon="/assets/icon/delivery_big.svg"
            title={dictionary.guide.delivery}
            link={app_links.delivery}
            isMobileScreen={props.isMobileScreen}
          />
          <Box w="8px" />
          <FooterIconLink
            icon="/assets/icon/question_big.svg"
            title={dictionary.guide.qa}
            link={app_links.question}
            isMobileScreen={props.isMobileScreen}
          />
          <Box w="8px" />
          <FooterIconLink
            icon="/assets/icon/contact_big.svg"
            title={dictionary.guide.contact}
            link={
              props.user?.isAnonymous ?? true
                ? 'https://docs.google.com/forms/d/e/1FAIpQLSenvL-3jMbOgHgOXp4KUk9b4vVhLPFOky1FD6Dlnbz86LCigQ/viewform?usp=sf_link'
                : `https://docs.google.com/forms/d/e/1FAIpQLSeiVgMABhJ1P2-4bGyLGB0mI7fEj0zSKdXtORXDCUw7hz7SkQ/viewform?usp=pp_url&entry.828252361=${props.user?.uid}`
            }
            last={true}
            isMobileScreen={props.isMobileScreen}
          />
        </Flex>
        <Box h="32px" />
      </Flex>
    );
  }
};
