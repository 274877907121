'use client';
import useAspidaSWR from '@aspida/swr';

import { useEffect } from 'react';
import { apiClient } from '~/lib/apiClient';
import { useUser } from '~/hooks/resources';
import { isActiveToken } from '~/src/services/users';

import type { Order } from '~/types/resources';

type UseOrders = {
  orders: Order[];
  loading: boolean;
  mutate: () => void;
  error: any;
};

export const useOrders = (): UseOrders => {
  const { token } = useUser();
  const { data, error, mutate } = useAspidaSWR(apiClient.v1.orders, {
    headers: {
      Authorization: `Bearer ${token?.token}`,
    },
    enabled: token != null && isActiveToken(token),
  });

  useEffect(() => {
    mutate();
  }, [token]);

  return {
    orders: data?.orders ?? [],
    loading: !data && !error,
    mutate,
    error,
  };
};
