import { apiClient } from '../apiClient';
export const postOneTimePassword = async (code: string, token: string) => {
  try {
    const response = await apiClient.v1.me.ocodes.post({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: { code },
    });
    return response;
  } catch (error) {
    console.error('エラー', error);
    throw error;
  }
};
