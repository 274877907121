import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
} from '~/constants/languages';

const dictionaries = {
  ja: () =>
    import('../../dictionaries/ja.json').then((module) => module.default),
  en: () =>
    import('../../dictionaries/en.json').then((module) => module.default),
  kr: () =>
    import('../../dictionaries/kr.json').then((module) => module.default),
  'zh-CN': () =>
    import('../../dictionaries/zh-CN.json').then((module) => module.default),
};

export const getLanguageDictionary = async (language: SupportedLanguage) => {
  const dictionary = await dictionaries[language]();
  return dictionary;
};

export const convertLanguageToISO = (language: SupportedLanguage) => {
  if (language === 'en') return 'en-US';
  if (language === 'kr') return 'ko';
  if (language === 'zh-CN') return 'zh';
  return language;
};

export const defaultNS = 'translation';

export function getOptions(lng: SupportedLanguage, ns = defaultNS) {
  return {
    resources: {
      en: {
        translation: require('../../dictionaries/en.json'),
      },
      ja: {
        translation: require('../../dictionaries/ja.json'),
      },
      kr: {
        translation: require('../../dictionaries/kr.json'),
      },
      'zh-CN': {
        translation: require('../../dictionaries/zh-CN.json'),
      },
    },
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    lng: lng || DEFAULT_LANGUAGE,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
