import {
  Flex,
  Button,
  DrawerHeader,
  DrawerBody,
  Spinner,
} from '@chakra-ui/react';

import { LoginProviders } from './LoginProviders';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  loading: boolean;
  onLoginRedirect: () => void;
};

export const SignupBottomDrawerContent: React.FC<Props> = (props: Props) => {
  return (
    <>
      <DrawerHeader textAlign="center">ユーザ登録</DrawerHeader>
      <DrawerBody m={0} p={0}>
        {!props.loading ? <LoginProviders methods="signup" /> : <Spinner />}
        <Flex px={2} pt={4} pb={8}>
          <Button variant="outline" w="full" onClick={props.onLoginRedirect}>
            ログインはこちら
          </Button>
        </Flex>
      </DrawerBody>
    </>
  );
};
