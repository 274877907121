import type { FirebaseApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import type { Auth } from 'firebase/auth';
import { getAuth } from 'firebase/auth';

import { firebaseConfig } from './firebase-key';

let _app: FirebaseApp | null = null;

export const getApp = (): FirebaseApp => {
  if (_app) return _app;
  return (_app = initializeApp(firebaseConfig));
};

export const getAuthInstance = (): Auth => {
  getApp();
  return getAuth();
};
