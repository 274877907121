import { useContext, useEffect } from 'react';
import { RootContext, DispatchContext } from '~/store/index';
import { initFocus } from '~/src/services/config';

type UseWindow = {
  isWindowVisible: boolean;
};

export const useWindow = (): UseWindow => {
  const { isWindowVisible } = useContext(RootContext);
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    initFocus(refreshWindowVisibleState);
  }, []);

  const refreshWindowVisibleState = () => {
    const isWindowVisible = document.hasFocus();
    setWindowVisible(isWindowVisible);
  };

  const setWindowVisible = async (isWindowVisible: boolean) =>
    dispatch({
      type: 'SET_WINDOW_VISIBLE',
      payload: {
        isWindowVisible,
      },
    });

  return {
    isWindowVisible: isWindowVisible,
  };
};
