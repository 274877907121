'use client';
import ReactGA from 'react-ga4';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

const setAnalytics = () => {
  const pathname = usePathname();

  useEffect(() => {
    ReactGA.initialize('G-5BE62LDMEH');
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
    });
  }, [pathname]);
};

export default setAnalytics;
