import Image from 'next/image';
import {
  HStack,
  VStack,
  Text,
  Card,
  CardHeader,
  CardBody,
  Box,
} from '@chakra-ui/react';
import { useArcConfig } from '~/hooks/resources';
import RollIcon from 'public/assets/roll.png';

export const ArcConfigContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { arcConfig, isLoading } = useArcConfig();

  const gradientRed = 'rgba(210, 70, 67,0.68)';
  const gradientPurple = 'rgba(157, 87, 199,0.68)';
  const gradientBlue = 'rgba(40, 113, 242,0.68)';

  //         bgGradient="linear(70deg, gradientRed, gradientPurple,gradientBlue), linear-gradient(185deg, rgba(69, 54, 255, 0.8), rgba(255, 255, 255, 0)),, linear-gradient(340deg, rgba(0, 153, 26, 0.8), rgba(255, 255, 255, 0))"

  // ここのロード中はスルーする
  // メンテナンスモードであることが確認できてから表示に入る
  if (!isLoading && arcConfig && arcConfig.maintenance.enable) {
    return (
      <VStack
        h="100vh"
        alignItems="center"
        justifyContent="center"
        color="white"
        bgImage={`linear(70deg, ${gradientRed}, ${gradientPurple}, ${gradientBlue}), linear-gradient(0deg, ${gradientPurple}, rgba(255, 255, 255, 0))`}
        px={4}
      >
        <Card minH="500px" maxW="400px" w="full">
          <CardHeader textAlign="center">
            <HStack alignItems="center" justifyContent="center">
              <Box w={12}>
                <Image src={RollIcon} alt="メンテナンス中" />
              </Box>
              <Text fontWeight="bold" fontSize="xl">
                Web ROLL
              </Text>
            </HStack>
          </CardHeader>
          <CardBody>
            <Text fontSize="xl" textAlign="center" fontWeight="semibold">
              {arcConfig.maintenance.title}
            </Text>
            <Box my={4}>
              <Text whiteSpace="pre-wrap" fontSize="md">
                {arcConfig.maintenance.content}
              </Text>
            </Box>
          </CardBody>
        </Card>
      </VStack>
    );
  }

  return <>{children}</>;
};
