import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Link,
  Checkbox,
  Icon,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  FormLabel,
  FormControl,
  Text,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai';

import { PasswordResetModal } from './PasswordResetModal';
import { useI18nLn } from '~/hooks/store';
import { app_links } from '~/lib/app_links';
import type { SigninMethods } from '~/types/context';

export type LoginFormData = {
  email: string;
  password: string;
  checked?: boolean;
};

type Props = {
  onSubmit: (data: LoginFormData) => Promise<void>;
  errorMessage: string;
  setErrorMessage: (str0: string) => void;
  methods: SigninMethods;
  submitting: boolean;
};

export const LoginForm: React.FC<Props> = (props: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useFormContext<LoginFormData>();
  const { t } = useI18nLn();
  const disclosure = useDisclosure();

  useEffect(() => {
    if (props.errorMessage !== '') {
      setError('password', { type: 'manual', message: props.errorMessage });
      props.setErrorMessage('');
    }
  }, [props.errorMessage]);

  return (
    <>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <FormControl>
          <FormLabel m={0} fontSize="xs">
            {t('guide.login_form.email')}
          </FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={AiOutlineMail} color="primary" w={5} h={5} />
            </InputLeftElement>
            <Input
              {...register('email', { required: true })}
              size="md"
              focusBorderColor="primary"
              variant="flushed"
              type="email"
              placeholder="email"
            />
          </InputGroup>
          <Box h={2} />
          <Flex flexWrap="wrap">
            <Text width="full" fontSize="xs" textColor="red">
              {errors.email !== undefined
                ? 'メールアドレスを入力してください'
                : ''}
            </Text>
          </Flex>
        </FormControl>
        <FormControl my={2}>
          <FormLabel m={0} fontSize="xs">
            {t('guide.login_form.password')}
          </FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={AiOutlineLock} color="primary" w={5} h={5} />
            </InputLeftElement>
            <Input
              {...register('password', { required: true })}
              size="md"
              focusBorderColor="primary"
              variant="flushed"
              type="password"
              placeholder="password"
            />
          </InputGroup>
          <Box h={2} />
          <Flex flexWrap="wrap">
            <Text width="full" fontSize="xs" textColor="red">
              {errors.password !== undefined
                ? errors.password.message ?? 'パスワードを入力してください'
                : ''}
            </Text>
          </Flex>
        </FormControl>
        {props.methods === 'signup' ? (
          <FormControl>
            <Flex justifyContent="flex-end" mt={4} mb={2}>
              <Text fontSize="xs" color="gray.500" mr={2}>
                <Link
                  textDecoration="underline"
                  isExternal
                  href={app_links.terms}
                >
                  {t('global.common.terms')}
                </Link>
                、
                <Link
                  textDecoration="underline"
                  isExternal
                  href={app_links.privacy_policy}
                >
                  {t('global.common.privacy')}
                </Link>
                {t('guide.login_form.agree')}
              </Text>
              <Checkbox
                colorScheme="green"
                size="lg"
                {...register('checked', {
                  required:
                    props.methods === 'signup'
                      ? '利用規約等の同意にチェックしてください'
                      : false,
                })}
              />
            </Flex>
            <Flex flexWrap="wrap">
              <Text width="full" fontSize="xs" textColor="red" align="right">
                {errors.checked?.message}
              </Text>
            </Flex>
          </FormControl>
        ) : (
          <Flex align="right" justify="right">
            <Button
              h="32px"
              textColor="main"
              bgColor="white"
              border="1px"
              borderColor="border"
              fontSize="11px"
              onClick={disclosure.onOpen}
            >
              {t('guide.login_form.forgot_password')}
            </Button>
          </Flex>
        )}
        <Flex justifyContent="flex-end" mt={4}>
          <Button
            type="submit"
            variant="primary"
            w="full"
            isLoading={props.submitting}
          >
            {props.methods === 'signup'
              ? t('guide.login_form.signup_button')
              : t('guide.login_form.login_button')}
          </Button>
        </Flex>
      </form>
      <PasswordResetModal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
      />
    </>
  );
};
