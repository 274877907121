import {
  type Auth,
  type IdTokenResult,
  signInAnonymously,
} from 'firebase/auth';
import axios from 'axios';
import { icon_base64 } from '~/lib/default_icon_base64';
import { apiClient } from '~/lib/apiClient';
export * from './id_token_visibility_event';

// 有効期限が切れる前にトークンを更新する
export const isRequiredToRefreshToken = (token: IdTokenResult): boolean => {
  const REQUIRED_REFRESH_TIME = 5 * 60 * 1000;
  return (
    new Date(token.expirationTime).getTime() - Date.now() <
    REQUIRED_REFRESH_TIME
  );
};

export const isActiveToken = (token: IdTokenResult): boolean => {
  return !isTokenExpired(token);
};

export const isTokenExpired = (token: IdTokenResult): boolean => {
  const now = new Date();
  const expirationTime = new Date(token.expirationTime);
  return now.getTime() > expirationTime.getTime();
};

export const setupAnonymousUser = async (
  auth: Auth
): Promise<IdTokenResult> => {
  const anonymousResult = await signInAnonymously(auth);
  const anonymousToken = await anonymousResult.user.getIdTokenResult(true);
  try {
    await apiClient.v1.me.get({
      headers: {
        Authorization: `Bearer ${anonymousToken.token}`,
      },
    });
  } catch (e) {
    await apiClient.v1.users.signup.post({
      headers: {
        Authorization: `Bearer ${anonymousToken.token}`,
      },
    });
  }

  return anonymousToken;
};

export const initializeArcApiUser = async (token: string) => {
  try {
    const res = await apiClient.v1.me.get({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status == 404) {
      await apiClient.v1.users.signup.post({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await initializeUserDefaultIcon(token);
    }
  } catch (e) {
    return;
  }
};

const initializeUserDefaultIcon = async (token: string) => {
  try {
    const default_icon_url = await base64toFile(
      pickDefaultUserIconBinaryString(),
      token
    );

    await apiClient.v1.me.infos.patch({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: {
        icon_image_url: default_icon_url,
      },
    });
  } catch (e) {
    return;
  }
};

const pickDefaultUserIconBinaryString = (): string => {
  const random = createRandomInteger(4, 0);
  return convertBase64(random);
};

const createRandomInteger = (max: number, min: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const convertBase64 = (random: number): string => {
  const base64Icons = [
    icon_base64.icon1,
    icon_base64.icon2,
    icon_base64.icon3,
    icon_base64.icon4,
    icon_base64.icon5,
  ];
  return base64Icons[Math.round(random)];
};

const base64toFile = async (base64: string, token: string) => {
  // Base64データをデコードしてBlobオブジェクトを作成
  const byteCharacters = window.atob(base64.replace(/^.*,/, ''));
  const byteArrays: number[] = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    const char = byteCharacters.charCodeAt(i);
    byteArrays.push(char);
  }
  const byteArray = new Uint8Array(byteArrays);
  const blob = new Blob([byteArray], { type: 'image/png' });
  // BlobオブジェクトからFileオブジェクトを作成
  const file = new File([blob], 'image.png', { type: 'image/png' });
  const url = await uploadImage(file, token);
  return url;
};

const uploadImage = async (image_file: File, token: string) => {
  const pre_signed_url = await getPresignedPostUrl(image_file.name, token);

  if (typeof pre_signed_url === 'string') {
    const upload_res = await axios.put(pre_signed_url, image_file, {
      headers: {
        ContentType: image_file.type,
      },
    });
    const image_url = upload_res.request.responseURL.split('?')[0];
    return image_url;
  }
};

const getPresignedPostUrl = async (
  image_name: string,
  token: string
): Promise<string> => {
  const extension = image_name.substring(image_name.lastIndexOf('.') + 1);

  const pre_signed_res = await apiClient.v1.me.icons.upload.post({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: {
      extension_type: extension,
    },
  });
  return pre_signed_res.body.presigned_url;
};
