import NextImage from 'next/image';
import {
  Container,
  Center,
  Text,
  chakra,
  Image,
  Flex,
  useDisclosure,
  Slide,
  Box,
  Divider,
  Button,
  Avatar,
} from '@chakra-ui/react';
import { useParams } from 'next/navigation';
import type { User } from 'firebase/auth';
import NextLink from 'next/link';
import { pagesPath } from '~/lib/$path';
import { HeaderMenuDrawer } from '~/components/common/headers/HeaderMenuDrawer';
import { LanguageSelectBox } from '~/components/common/LanguageSelectBox';
import { useI18nLn } from '~/hooks/store';
import { useScreenCheck } from '~/hooks/resources/screen_check';
import { useDeliveryInfo } from '~/hooks/resources/delivery_info';

type Props = {
  user?: User;
  logoutHandler: () => Promise<void>;
  hidden?: boolean;
  title?: string;
};

export const Header: React.FC<Props> = (props: Props) => {
  const params = useParams();
  const { t } = useI18nLn();
  const disclosures = useDisclosure();
  const gacha_id = params?.gacha_id as string;
  const screenCheck = useScreenCheck();
  const { icon_image_url, loading } = useDeliveryInfo();
  const noneMeIcon = icon_image_url == null;
  const MD_HEIGHT = 60;
  const BASE_HEIGHT = 60;

  return (
    <>
      <chakra.header
        display="flex"
        backgroundColor="white"
        textColor="gray.800"
        position="absolute"
        zIndex={2}
        top={0}
        h={{ base: `${BASE_HEIGHT}px`, md: `${MD_HEIGHT}px` }}
        sx={{
          '> div': {
            height: { base: `${BASE_HEIGHT}px`, md: `${MD_HEIGHT}px` },
            w: 'full',
            bgColor: 'white',
          },
        }}
      >
        <Slide direction="top" in={!props.hidden}>
          <Container
            display="flex"
            flexDir="column"
            maxW="container.xl"
            h="full"
            justifyContent="center"
          >
            <Flex flexDir="row" bgColor="white">
              <NextLink
                href={
                  gacha_id ? pagesPath.e._gacha_id(`${gacha_id}`).$url() : '/e'
                }
              >
                <Flex alignItems="center" h="full">
                  <Flex h="full" minW="160px" position="relative">
                    <NextImage
                      src="/assets/at-yell.jpeg"
                      alt="logo"
                      fill={true}
                      objectFit="contain"
                    />
                  </Flex>
                </Flex>
              </NextLink>
              <Flex flex={2} />

              <Button
                h="60px"
                w={props.user?.isAnonymous ?? true ? '168px' : '120px'}
                border="none"
                aria-label="メニュー"
                bgColor="white"
                onClick={() => disclosures.onOpen()}
                m={0}
                backgroundColor="#1fe0"
                _hover={{ backgroundColor: '#1fe0' }}
                _focus={{ backgroundColor: '#1fe0' }}
                _active={{ backgroundColor: '#1fe0' }}
              >
                {props.user?.isAnonymous ?? true ? (
                  <Image
                    src={'/assets/icon/profile.svg'}
                    alt="icon"
                    h="20px"
                    w="20px"
                    my="18px"
                    mx="12px"
                  />
                ) : loading || noneMeIcon ? (
                  <Image
                    src={'/assets/icon/profile.svg'}
                    alt="icon"
                    h="20px"
                    w="20px"
                    my="18px"
                    mx="12px"
                  />
                ) : (
                  <Avatar
                    src={icon_image_url}
                    h="24px"
                    w="24px"
                    my="18px"
                    mx="12px"
                    style={{ clipPath: 'circle(50% at 50% 50%)' }}
                    objectFit="cover"
                  />
                )}

                <Text
                  fontSize="12px"
                  fontWeight="bold"
                  textColor="main"
                  pt={{ base: 1 }}
                  pr={{ md: 1 }}
                >
                  {props.user?.isAnonymous ?? true
                    ? t('global.header.signup_or_login')
                    : t('global.header.menu')}
                </Text>
              </Button>
            </Flex>
          </Container>
          {props.title ? (
            <Flex
              h="24px"
              alignItems="center"
              justifyContent="center"
              bgColor="white"
            >
              <Text fontWeight="600" mb={2}>
                {props.title}
              </Text>
            </Flex>
          ) : null}
          <Divider />
        </Slide>
        <HeaderMenuDrawer
          {...disclosures}
          logged_in={!(props.user?.isAnonymous ?? true)}
          isMobileScreen={screenCheck.isMobileScreen}
        />
      </chakra.header>
      <Box h={{ base: `${BASE_HEIGHT}px`, md: `${MD_HEIGHT}px` }} />
    </>
  );
};
