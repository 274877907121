import { Progress, Flex } from '@chakra-ui/react';

type Props = {
  value: number;
  isLoad: boolean;
};

export const DisplayUpdateProgressBar = (props: Props) => {
  if (!props.isLoad) return null;
  return (
    <Flex w="full" position="fixed" top="0" zIndex="5">
      <Progress
        w="full"
        size="sm"
        value={props.value}
        colorScheme="messenger"
      />
    </Flex>
  );
};
