import React from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import { FooterLink } from './FooterLink';
import { app_links } from '~/lib/app_links';
import { useI18nLn } from '~/hooks/store';

type Props = {
  isMobileScreen: boolean;
  bottom_margin?: number | null;
};

export const FooterInfo: React.FC<Props> = (props: Props) => {
  const { t } = useI18nLn();
  if (props.isMobileScreen) {
    return (
      <Flex
        flexDir="column"
        w="full"
        bgColor="main"
        justify="left"
        align="baseline"
      >
        <Box h="24px" />
        <FooterLink
          title={t('global.footer.company')}
          link={app_links.three_bitter}
        />
        <FooterLink
          title={t('global.footer.privacy')}
          link={app_links.privacy_policy}
        />
        <FooterLink title={t('global.footer.terms')} link={app_links.terms} />
        <FooterLink
          title={t('global.footer.contact')}
          link={app_links.commarcial_transaction}
        />
        <Box h="32px" />
        <Flex align="center" justify="center" w="full">
          <Text fontSize="11px" textColor="disable">
            ©︎ 3bitter inc.
          </Text>
        </Flex>
        <Box h={`${(props.bottom_margin ?? 0) + 104}px`} />
      </Flex>
    );
  } else {
    return (
      <Flex
        flexDir="column"
        w="full"
        bgColor="main"
        alignItems="center"
        justify="center"
        align="baseline"
      >
        <Box h="32px" />
        <Flex
          flexDir="row"
          w="full"
          px="8px"
          alignItems="center"
          justify="center"
        >
          <FooterLink
            title={t('global.footer.company')}
            link={app_links.three_bitter}
          />
          <FooterLink
            title={t('global.footer.privacy')}
            link={app_links.privacy_policy}
          />
          <FooterLink title={t('global.footer.terms')} link={app_links.terms} />
          <FooterLink
            title={t('global.footer.specified_commercial_transactions')}
            link={app_links.commarcial_transaction}
          />
        </Flex>
        <Box h="40px" />
        <Flex align="center" justify="center" w="full">
          <Text fontSize="11px" textColor="disable">
            ©︎ 3bitter inc.
          </Text>
        </Flex>
        <Box h={`${(props.bottom_margin ?? 0) + 104}px`} />
      </Flex>
    );
  }
};
