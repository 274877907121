import { useContext } from 'react';
import { RootContext, DispatchContext } from '~/store/index';

import type { ErrorMessage } from '~/types/error';

type UsePageError = {
  page_error?: ErrorMessage;
  setPageError: (error_message: ErrorMessage | null) => Promise<void>;
  reset: () => Promise<void>;
};

export const usePageError = (): UsePageError => {
  const { page_error } = useContext(RootContext);
  const dispatch = useContext(DispatchContext);

  const setPageError = async (error_message: ErrorMessage | null) =>
    dispatch({
      type: 'SET_ERROR',
      payload: {
        page_error: error_message ?? null,
      },
    });

  const reset = async () => {
    dispatch({
      type: 'SET_ERROR',
      payload: {
        page_error: null,
      },
    });
  };

  return {
    page_error: page_error ?? undefined,
    setPageError,
    reset,
  };
};
