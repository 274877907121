import type { RootState, ActionType } from '~/types';

export const ACTION_TYPE_MAP = {
  SET_TOKEN: 'SET_TOKEN',
  SET_ERROR: 'SET_ERROR',
  SET_GUIDE_SIGNIN: 'SET_GUIDE_SIGNIN',
  SET_I18N_LN: 'SET_I18N_LN',
  SET_WINDOW_VISIBLE: 'SET_WINDOW_VISIBLE',
} as const;

export const reducer = (state: RootState, action: ActionType): RootState => {
  switch (action.type) {
    case ACTION_TYPE_MAP.SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case ACTION_TYPE_MAP.SET_ERROR:
      return {
        ...state,
        page_error: action.payload.page_error,
      };
    case ACTION_TYPE_MAP.SET_GUIDE_SIGNIN:
      return {
        ...state,
        guide_signin: action.payload.guide_signin ?? null,
      };
    case ACTION_TYPE_MAP.SET_I18N_LN:
      return {
        ...state,
        i18nLn: action.payload.i18nLn ?? null,
      };
    case ACTION_TYPE_MAP.SET_WINDOW_VISIBLE:
      return {
        ...state,
        isWindowVisible: action.payload.isWindowVisible,
      };
    default:
      return state;
  }
};
