/* eslint-disable */
// prettier-ignore
import { Query as Query0 } from '../pages/login'

// prettier-ignore
export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  $500: {
    $url: (url?: { hash?: string }) => ({ pathname: '/500' as const, hash: url?.hash })
  },
  e: {
    _gacha_id: (gacha_id: string | number) => ({
      _play_amount: (play_amount: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/[play_amount]' as const, query: { gacha_id, play_amount }, hash: url?.hash })
      }),
      collection: {
        $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/collection' as const, query: { gacha_id }, hash: url?.hash })
      },
      me: {
        $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/me' as const, query: { gacha_id }, hash: url?.hash })
      },
      orders: {
        _order_id: (order_id: string | number) => ({
          open: {
            $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/orders/[order_id]/open' as const, query: { gacha_id, order_id }, hash: url?.hash })
          },
          paypay: {
            $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/orders/[order_id]/paypay' as const, query: { gacha_id, order_id }, hash: url?.hash })
          },
          redirect: {
            $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/orders/[order_id]/redirect' as const, query: { gacha_id, order_id }, hash: url?.hash })
          },
          result: {
            $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/orders/[order_id]/result' as const, query: { gacha_id, order_id }, hash: url?.hash })
          },
          $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/orders/[order_id]' as const, query: { gacha_id, order_id }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]/orders' as const, query: { gacha_id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/e/[gacha_id]' as const, query: { gacha_id }, hash: url?.hash })
    })
  },
  info: {
    $url: (url?: { hash?: string }) => ({ pathname: '/info' as const, hash: url?.hash })
  },
  login: {
    $url: (url: { query: Query0, hash?: string }) => ({ pathname: '/login' as const, query: url.query, hash: url.hash })
  },
  me: {
    $url: (url?: { hash?: string }) => ({ pathname: '/me' as const, hash: url?.hash })
  },
  orders: {
    _order_id: (order_id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/orders/[order_id]' as const, query: { order_id }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/orders' as const, hash: url?.hash })
  },
  signup: {
    $url: (url?: { hash?: string }) => ({ pathname: '/signup' as const, hash: url?.hash })
  }
}

// prettier-ignore
export type PagesPath = typeof pagesPath
