import type { UserInfo } from 'firebase/auth';

export const parseProviderName = (provider_infos: UserInfo[]): string => {
  if (!provider_infos) return 'unknown';
  if (provider_infos.length <= 0) return 'unknown';
  switch (provider_infos[0].providerId) {
    case 'google.com':
      return 'google';
    case 'password':
      return 'password';
    default:
      return 'unknown';
  }
};
