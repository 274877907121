export type AppLinks = {
  three_bitter: string; //3bitter
  terms: string; // 利用規約
  privacy_policy: string; //プライバシーポリシー
  commarcial_transaction: string; // 特定商取引
  how_to: string; // 利用方法
  question: string; //よくある質問
  webroll: string; //webrollとは？
  delivery: string; //配送について
  googlelogin_info: string; //IosでのGoogleログイン失敗について
};

export const app_links: AppLinks = {
  three_bitter: 'https://3bitter.com/',
  terms: 'https://ex.swmp.place/web-roll/kiyaku',
  privacy_policy: 'https://ex.swmp.place/web-roll/policy',
  commarcial_transaction: 'https://ex.swmp.place/web-roll/tokusyo',
  how_to: 'https://ex.swmp.place/web-roll/howtouse',
  question: 'https://ex.swmp.place/web-roll/faq',
  webroll: 'https://ex.swmp.place/web-roll/whatisWebROLL',
  delivery: 'https://ex.swmp.place/web-roll/delivery',
  googlelogin_info: 'https://ex.swmp.place/web-roll/info_googlelogin_ios',
};
