import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string | React.ReactNode;
};

export const ErrorModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent mx={2}>
        <ModalHeader mr={4}>{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody whiteSpace="pre-wrap">{props.body}</ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={props.onClose} variant="primary">
            閉じる
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
