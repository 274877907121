import React from 'react';

import { SigninDrawer } from './SigninDrawer';
import { useGuideSignin } from '~/hooks/store';
import { useUser } from '~/hooks/resources';

// アクションに対してユーザが必須の場合等はボトムシートにてログインを促す際に使用する
// 例：注文ボタンを押した際、注文は非ログイン状態では行えないので遷移前にログインを促す
export const SigninDrawerContainer: React.FC = () => {
  const { loading } = useUser();
  const { guide_signin, setGuideSignin } = useGuideSignin();

  return (
    <SigninDrawer
      loading={loading}
      guide_signin={guide_signin ?? null}
      setGuideSignin={setGuideSignin}
    />
  );
};
