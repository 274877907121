import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useRouter, useParams, usePathname } from 'next/navigation';

import { OneTimePasswordFormModal } from '../modals/OneTimePasswordForm';
import { HeaderMenuRow } from './HeaderMenuRow';
import { useGuideSignin, useI18nLn } from '~/hooks/store';
import { useUser } from '~/hooks/resources';
import { app_links } from '~/lib/app_links';
import type { HeaderMenuAction } from '~/types/components';
import { parseProviderName } from '~/lib/texts/firebase';

type Props = {
  isOpen: boolean;
  onOpen: () => Promise<void> | void;
  onClose: () => void;
  logged_in: boolean;
  isMobileScreen: boolean;
};

export const HeaderMenuDrawer: React.FC<Props> = (props: Props) => {
  const toast = useToast();
  const router = useRouter();
  const { t } = useI18nLn();
  const pathname = usePathname();
  const params = useParams();
  const gacha_id = params?.gacha_id as string;
  const { user, logout } = useUser();
  const { setGuideSignin } = useGuideSignin();
  const inputDisclosure = useDisclosure();

  const registerAction = async () => {
    router.push('/signup');
    props.onClose();
  };
  const loginAction = async () => {
    router.push('/login');
    props.onClose();
  };

  const desc_actions: HeaderMenuAction[] = [
    {
      display_name: t('guide.what_is_service'),
      handleAction: async () => {
        window.open(app_links.webroll);
      },
      icon: '/assets/icon/webroll.svg',
      navigateIcon: true,
    },
    {
      display_name: t('guide.delivery'),
      handleAction: async () => {
        window.open(app_links.delivery);
      },
      icon: '/assets/icon/delivery.svg',
      navigateIcon: true,
    },
    {
      display_name: t('guide.qa'),
      handleAction: async () => {
        window.open(app_links.question);
      },
      icon: '/assets/icon/question.svg',
      navigateIcon: true,
    },
    {
      display_name: t('guide.contact'),
      handleAction: async () => {
        window.open(
          user?.isAnonymous ?? true
            ? 'https://docs.google.com/forms/d/e/1FAIpQLSenvL-3jMbOgHgOXp4KUk9b4vVhLPFOky1FD6Dlnbz86LCigQ/viewform?usp=sf_link'
            : `https://docs.google.com/forms/d/e/1FAIpQLSeiVgMABhJ1P2-4bGyLGB0mI7fEj0zSKdXtORXDCUw7hz7SkQ/viewform?usp=pp_url&entry.828252361=${user?.uid}`
        );
      },
      icon: '/assets/icon/contact.svg',
      navigateIcon: true,
    },
    {
      display_name: t('global.header.menus.input_code'),
      handleAction: inputDisclosure.onOpen,
      icon: '/assets/icon/code_checkin.svg',
      navigateIcon: true,
    },
    {
      display_name: t('global.footer.privacy'),
      handleAction: async () => {
        window.open(app_links.privacy_policy);
      },
      navigateIcon: true,
    },
    {
      display_name: t('global.footer.terms'),
      handleAction: async () => {
        window.open(app_links.terms);
      },
      navigateIcon: true,
    },
    {
      display_name: t('global.footer.specified_commercial_transactions'),
      handleAction: async () => {
        window.open(app_links.commarcial_transaction);
      },
      navigateIcon: true,
    },
  ];

  const unlogin_actions: HeaderMenuAction[] = desc_actions;

  const login_actions: HeaderMenuAction[] = [
    {
      display_name: t(
        `global.login.status.${parseProviderName(user?.providerData ?? [])}`
      ),
      icon: '/assets/icon/account.svg',
      navigateIcon: false,
    },
    {
      display_name: t('global.header.menus.mypage'),
      href: gacha_id ? `/e/${gacha_id}/me` : `/me`,
      handleAction: async () => {
        props.onClose();
      },
      icon: '/assets/icon/my_page.svg',
      navigateIcon: true,
    },
    ...desc_actions,
    {
      display_name: t('global.header.menus.logout'),
      handleAction: async () => {
        await logout();
        router.replace(pathname as string);
        toast({
          title: 'ログアウトしました',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        localStorage.setItem('modal', 'close');
      },
      navigateIcon: true,
    },
  ];

  const actions = props.logged_in ? login_actions : unlogin_actions;

  return (
    <>
      <Drawer
        placement={props.isMobileScreen ? 'bottom' : 'right'}
        onClose={props.onClose}
        isOpen={props.isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent rounded={{ base: '13px 13px 0 0', md: '13px 0 0 13px' }}>
          <DrawerHeader pt="8px" pb="0" px="8px">
            <Flex flexDir="row" w="full" justify="right" align="end">
              <Image
                src="/assets/icon/close.svg"
                alt="icon"
                h="28px"
                w="28px"
                onClick={props.onClose}
              />
            </Flex>
          </DrawerHeader>
          <DrawerBody p={0} mb={2}>
            <Box h="32px" />
            {!props.logged_in && (
              <Flex flexDir="column" w="full" align="center" justify="center">
                <Button
                  h="48px"
                  w="240px"
                  px="16px"
                  bgColor="white"
                  bgGradient="linear(to-r, gradientRed, gradientPurple,gradientBlue)"
                  fontSize="16px"
                  fontWeight="bold"
                  rounded="50px"
                  backgroundColor="#1fe0"
                  _hover={{ backgroundColor: '#1fe0' }}
                  _focus={{ backgroundColor: '#1fe0' }}
                  _active={{ backgroundColor: '#1fe0' }}
                  onClick={loginAction}
                >
                  {t('global.header.menus.login')}
                </Button>
                <Box h="16px" />
                <Text fontSize="12px">{t('guide.guide_to_signup')}</Text>
                <Box h="4px" />
                <Button
                  h="48px"
                  w="240px"
                  px="16px"
                  bgColor="white"
                  fontSize="13px"
                  fontWeight="bold"
                  textColor="main"
                  rounded="50px"
                  border="1px"
                  borderColor="border"
                  backgroundColor="#1fe0"
                  _hover={{ backgroundColor: '#1fe0' }}
                  _focus={{ backgroundColor: '#1fe0' }}
                  _active={{ backgroundColor: '#1fe0' }}
                  onClick={registerAction}
                >
                  {t('global.header.menus.signup')}
                </Button>
                <Box h="32px" />
              </Flex>
            )}
            {actions.map((action, index) => {
              return <HeaderMenuRow {...action} key={index} />;
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <OneTimePasswordFormModal
        isOpen={inputDisclosure.isOpen}
        onClose={inputDisclosure.onClose}
      />
    </>
  );
};
