import aspida, { FetchConfig } from '@aspida/fetch';
import api from '~/api/$api';

const config: FetchConfig = {
  baseURL:
    process.env.NEXT_PUBLIC_BASE_URL ?? 'https://devapi.3barc.space/api/', //stg
  //'https://api.3barc.space/api/', //prod,
};

export const apiClient = api(aspida(fetch, config));
