export const SUPPORTED_LANGUAGES = ['ja', 'en', 'kr', 'zh-CN'] as const;

export type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];

export const DEFAULT_LANGUAGE: SupportedLanguage = 'ja';

export const SUPPORTED_LANGUAGES_MAP: Record<SupportedLanguage, string> = {
  ja: '日本語',
  en: 'English',
  kr: '한국어',
  'zh-CN': '汉语（简体字）',
};
