import { CLIENT_ERROR_CODES, CLIENT_ERROR_DESCRIPTIONS } from './client';
import { API_ERROR_CODES, API_ERROR_DESCRIPTIONS } from './api';

export type ErrorCodeKey = keyof typeof ERROR_CODES;
export type ErrorCodeValue = typeof ERROR_CODES[ErrorCodeKey];
export type ErrorDescription = { title: string; description: string };

export const ERROR_CODES = {
  ...CLIENT_ERROR_CODES,
  ...API_ERROR_CODES,
};

export const ERROR_DESCRIPTIONS = {
  ...CLIENT_ERROR_DESCRIPTIONS,
  ...API_ERROR_DESCRIPTIONS,
};
