import { useEffect, useState } from 'react';
import { Router } from 'next/router';

export const useProgressBarUpdateTimer = () => {
  const [isDisplayUpdate, setIsDisplayUpdate] = useState<boolean>(false);
  const [progressCount, setProgressCount] = useState(0);
  const [countTimer, setCountTimer] = useState<NodeJS.Timer>();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setProgressCount(0);
      setIsDisplayUpdate(true);

      const countTimer = setInterval(() => {
        setProgressCount((prevProgressCount) => {
          const newProgressCount = prevProgressCount + 1;
          if (newProgressCount >= 80) {
            clearInterval(countTimer);
          }
          return newProgressCount;
        });
      }, 100);

      setCountTimer(countTimer);
    };

    const handleRouteChangeComplete = () => {
      clearInterval(countTimer);
      setProgressCount(100);

      setTimeout(() => {
        setIsDisplayUpdate(false);
      }, 1000);
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);
    Router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
      Router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, []);

  return { progressCount, isDisplayUpdate };
};
