'use client';
import { useEffect } from 'react';
import useAspidaSWR from '@aspida/swr';

import { useUser } from '.';
import { apiClient } from '~/lib/apiClient';
import { useI18nLn } from '~/hooks/store';

import { convertLanguageToISO } from '~/src/services/i18n';
import type { Gacha } from '~/types/resources/gacha';

type UseGachaInfo = {
  gachas: Gacha[] | undefined;
  loading: boolean;
  error: Error;
  mutate: () => void;
};

export const useGachaInfo = (): UseGachaInfo => {
  const { token } = useUser();
  const { i18nLn } = useI18nLn();
  const query = () => {
    const isDefaultLang = i18nLn === 'ja' || i18nLn == null;
    const requestLang = !isDefaultLang ? convertLanguageToISO(i18nLn) : null;
    return {
      query: {
        ...(requestLang != null && { lang: requestLang }),
        provider_info: 'firebase:web-roll',
      },
    };
  };
  const { data, error, mutate } = useAspidaSWR(apiClient.v1.gachas, {
    ...(token != null && {
      headers: {
        Authorization: `Bearer ${token?.token}`,
      },
    }),
    ...query(),
  });

  useEffect(() => {
    mutate();
  }, [token?.claims?.sub]);

  return {
    gachas: data?.data ?? [],
    loading: !data && !error,
    error: error,
    mutate,
  };
};
