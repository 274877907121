import React from 'react';
import { Text, Flex } from '@chakra-ui/react';

type Props = {
  title: string;
  link: string;
};

export const FooterLink: React.FC<Props> = (props: Props) => {
  return (
    <Flex
      flexDir="row"
      justify="left"
      align="center"
      px="16px"
      pb="12px"
      onClick={() => {
        window.open(props.link, '_blank');
      }}
    >
      <Text fontSize="13px" textColor="blank">
        {props.title}
      </Text>
    </Flex>
  );
};
