import axios from 'axios';
import {
  ErrorCodeValue,
  ErrorCodeKey,
  ErrorDescription,
  ERROR_CODES,
  ERROR_DESCRIPTIONS,
} from '~/constants/errors';

type ArcApiError = {
  code: string;
  message: string;
};

export const convertErrorToErrorDescription = (
  error: unknown,
  defaultTitle: string
): Error => {
  const defaultError = createDefaultError(defaultTitle);
  if (!(error instanceof Error)) return defaultError;
  if (!axios.isAxiosError(error)) {
    defaultError.message = error.name;
    return defaultError;
  }
  if (error.response && isArcApiError(error.response?.data)) {
    const arcError = new Error(error.response.data.message);
    arcError.name = error.response.data.code;
    return arcError;
  }
  return defaultError;
};

export const convertApiErrorResponseToErrorDescription = (
  data: unknown,
  defaultTitle: string
): Error => {
  const defaultError = createDefaultError(defaultTitle);
  if (!isArcApiError(data)) return defaultError;
  const arcError = new Error(data.message);
  arcError.name = data.code;
  return arcError;
};

export const createDefaultError = (message: string): Error => {
  const error = new Error(message);
  error.name = '不明なエラーが発生しました';
  return error;
};

export const isArcApiError = (error: unknown): error is ArcApiError => {
  if (typeof error !== 'object' || error === null) return false;
  const { code, message } = error as ArcApiError;
  return typeof code === 'string' && typeof message === 'string';
};

export const findErrorCodeDescription = (
  code: ErrorCodeValue | string
): ErrorDescription | null => {
  const errorCodeKey: ErrorCodeKey =
    (Object.keys(ERROR_CODES) as ErrorCodeKey[]).find(
      (key) => ERROR_CODES[key] === code
    ) ?? 'UNEXPECTED_ERROR';
  const description = ERROR_DESCRIPTIONS[errorCodeKey];
  if (description == null) return null;
  return description;
};
