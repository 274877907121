import { ErrorDescription } from './';

export const API_ERROR_CODES = {
  ORDER_CREATE_FAILED: 'ORDER_CREATE_FAILED',
  INVALID_PARAMS: 'invalid_params',
};

export type ApiErrorCodeKeys = keyof typeof API_ERROR_CODES;
export type ApiErrorCodes = typeof API_ERROR_CODES[ApiErrorCodeKeys];

export const API_ERROR_DESCRIPTIONS: Record<
  ApiErrorCodeKeys,
  ErrorDescription
> = {
  ORDER_CREATE_FAILED: {
    title: '注文を作成できませんでした',
    description: '時間をおいて再度お試しください。',
  },
  INVALID_PARAMS: {
    title: 'パラメータが不正です',
    description: '住所と決済方法を確認してください。',
  },
};
