'use client';
import React, { useEffect, useState } from 'react';
import { useRouter, useParams } from 'next/navigation';
import { Box, Flex } from '@chakra-ui/react';

import { useUser } from '~/hooks/resources';
import { usePageError } from '~/hooks/store';
import { useScrollBorder } from '~/hooks/useScrollBoolean';
import { useProgressBarUpdateTimer } from '~/hooks/store/progress_bar_update_timer';
import { Header } from '~/components/common/Header';
import { ErrorModal } from '~/components/common/ErrorModal';
import { GuidanceOpenDefaultBrowser } from '~/components/errors/GuidanceOpenDefaultBrowser';
import { ErrorMessage } from '~/types/error';
import { Footer } from '~/components/common/Footer';
import { DisplayUpdateProgressBar } from '~/components/common/DisplayUpdateProgressBar';

type Props = {
  children: React.ReactNode;
  floating?: React.ReactNode;
  error?: ErrorMessage;
  before_caution?: boolean | null;
  bottom_margin?: number;
  is_show_error?: boolean;
  closeError?: () => void;
  page_title?: string;
  max_width?: number | boolean;
  float_bg_color?: string;
  hidden_header?: boolean;
  hidden_footer?: boolean;
};

export const GlobalLayout: React.FC<Props> = (props: Props) => {
  const router = useRouter();
  const params = useParams();
  const { user, logout } = useUser();
  const { isActive } = useScrollBorder(30);
  const { page_error, reset } = usePageError();
  const { progressCount, isDisplayUpdate } = useProgressBarUpdateTimer();
  const [is_webview, setWebview] = useState<boolean>(false);
  const maxW = (() => {
    if (typeof props.max_width === 'number') return props.max_width;
    if (props.max_width) {
      return 600;
    } else {
      return 'full';
    }
  })();

  useEffect(() => {
    if (params?.redirect_url) {
      router.replace(params.redirect_url as string);
    }
  }, [params]);

  useEffect(() => {
    if (navigator && navigator?.userAgent.indexOf('Line') !== -1) {
      setWebview(true);
    }
  }, []);

  if (is_webview) return <GuidanceOpenDefaultBrowser />;

  return (
    <>
      <Flex
        bgColor={props.float_bg_color ?? 'white'}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        minH="100vh"
        w="full"
      >
        <DisplayUpdateProgressBar
          value={progressCount}
          isLoad={isDisplayUpdate}
        />
        <Header
          user={user ?? undefined}
          logoutHandler={logout}
          hidden={!isActive || props.hidden_header}
          title={props.page_title}
        />
        <Flex maxW={maxW} w="full" justifyContent="center">
          {props.children}
        </Flex>
        {!props.hidden_footer && (
          <Footer
            user={user ?? undefined}
            before_caution={props.before_caution}
            bottom_margin={props.bottom_margin}
          />
        )}
      </Flex>
      {props.floating}
      {page_error ? (
        <ErrorModal
          isOpen={!!page_error}
          onClose={reset}
          title={page_error.title}
          body={page_error.body}
        />
      ) : null}
    </>
  );
};
