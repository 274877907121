'use client';
import useAspidaSWR from '@aspida/swr';

import { useEffect } from 'react';
import { useUser } from '.';
import { apiClient } from '~/lib/apiClient';
import { OrderOverview } from '~/types/resources/orderOverview';
import { isActiveToken } from '~/src/services/users';

type UseOrderIdInfo = {
  order: OrderOverview | undefined;
  loading: boolean;
  error: Error;
  mutate: () => void;
};

export const useOrderIdInfo = (order_id?: string): UseOrderIdInfo => {
  const { token } = useUser();
  const { data, error, mutate } = useAspidaSWR(
    apiClient.v1.orders._orderId(order_id as string),
    {
      headers: {
        Authorization: `Bearer ${token?.token}`,
      },
      enabled: token != null && isActiveToken(token) && order_id != null,
    }
  );

  useEffect(() => {
    mutate();
  }, [token]);

  return {
    order: data,
    loading: !data && !error,
    error: error,
    mutate,
  };
};
