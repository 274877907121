import { Flex, Text, Button } from '@chakra-ui/react';

export const GuidanceOpenDefaultBrowser: React.FC = () => {
  const redirectBrowser = async () => {
    location.replace(location.pathname + '?openExternalBrowser=1');
  };

  return (
    <Flex h="full" flexDir="column" m={4} mt={12}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>
        LINE内ブラウザで開かれています
      </Text>
      <Text>Web ROLLをご利用いただきありがとうございます。</Text>
      <Text>端末の標準ブラウザで再度このページを開いてください</Text>
      <Flex my={2}>
        <Text>
          Copy the URL of the page and open this page again with your
          device&apos;s default browser
        </Text>
      </Flex>
      <Button onClick={redirectBrowser} my={4} variant="primary">
        ブラウザに遷移
      </Button>
      <Text fontSize="md" fontWeight="bold">
        ブラウザに遷移できない場合はこちらをコピーしてご利用ください
      </Text>
      <Text userSelect="all" m={2}>
        {location.href}
      </Text>
    </Flex>
  );
};
