import {
  Heading,
  Flex,
  Text,
  Code,
  Button,
  ChakraProvider,
  Box,
} from '@chakra-ui/react';

import { theme } from '~/styles/theme';
import SeoHead from '~/components/common/Head';
import { GlobalLayout } from '~/layouts/GlobalLayout';
import { usePageError } from '~/hooks/store';

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const { page_error, setPageError } = usePageError();
  const redirectHome = () => {
    window.location.href = '/';
  };

  return (
    <ChakraProvider theme={theme}>
      <GlobalLayout
        error={page_error}
        is_show_error={page_error !== null}
        closeError={() => setPageError(null)}
        page_title="エラーが発生しました"
      >
        <SeoHead
          title="エラー"
          description="指定されたURLのページが存在しません"
          url="/error"
        />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          h="full"
          w="full"
        >
          <Flex flexDir="column" maxW="50%">
            <Heading fontSize="2xl" p={6} textAlign="center">
              500
            </Heading>
            <Text p={4}>指定されたURLのページが存在しません</Text>
            <Text p={4}>
              解決しない場合は
              <Code>メニュー &gt; お問い合わせ</Code>
              <br />
              よりお問い合わせください。
            </Text>
            <Button onClick={resetErrorBoundary} variant="outline" my={1}>
              リロードする
            </Button>
            <Button onClick={redirectHome} variant="primary" my={1}>
              トップに戻る
            </Button>
            <Box h="80px" />
          </Flex>
        </Flex>
      </GlobalLayout>
    </ChakraProvider>
  );
};
