import { Flex, Text, Divider, Image, Spacer, Box } from '@chakra-ui/react';

import NextLink from 'next/link';
import type { HeaderMenuAction } from '~/types/components';

export const HeaderMenuRow: React.FC<HeaderMenuAction> = (
  props: HeaderMenuAction
) => {
  const item = (
    <Flex flexDir="column" onClick={props.handleAction} px="16px">
      <Flex flexDir="row" py="12px" justify="center" align="center">
        {props.icon && (
          <>
            <Flex
              alignItems="center"
              justifyContent="flex-start"
              color="primary"
            >
              <Image src={props.icon} h="24px" w="24px" color="primary" />
            </Flex>
            <Box w="8px" />
          </>
        )}
        <Text fontSize="15px" fontWeight="bold">
          {props.display_name}
        </Text>
        <Spacer />
        {props.navigateIcon && (
          <Image src="/assets/icon/navigate.svg" alt="icon" h="16px" w="16px" />
        )}
      </Flex>
      <Divider />
    </Flex>
  );

  if (props.href) {
    return <NextLink href={props.href}>{item}</NextLink>;
  } else {
    return item;
  }
};
