import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import { SWRConfig } from 'swr';

import { RootProvider } from '~/store';
import { SigninDrawerContainer } from '~/components/login/SigninDrawerContainer';
import { theme } from '~/styles/theme';
import { ErrorFallbackPage } from '~/components/errors/ErrorFallbackPage';
import '~/styles/lottie.css';
import '~/styles/font.css';
import setAnalytics from '~/plugins/analytics';
import { ArcConfigContainer } from '~/components/common/ArcConfigContainer';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps) => {
  setAnalytics();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceWorker.js');
    }
  });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
      <RootProvider>
        <ChakraProvider theme={theme}>
          <SWRConfig
            value={{ refreshInterval: 1000 * 30, dedupingInterval: 1000 * 30 }}
          >
            <ArcConfigContainer>
              <Component {...pageProps} />
            </ArcConfigContainer>
          </SWRConfig>
          <SigninDrawerContainer />
        </ChakraProvider>
      </RootProvider>
    </ErrorBoundary>
  );
};

export default MyApp;
