import { ErrorDescription } from './';

export const CLIENT_ERROR_CODES = {
  CREDIT_CARD_NOT_SELECTED: 'CREDIT_CARD_NOT_SELECTED',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  CREDIT_PAYMENT_ERROR: 'CREDIT_PAYMENT_ERROR',
  PAYPAY_PAYMENT_ERROR: 'PAYPAY_PAYMENT_ERROR',
  ALIPAY_PAYMENT_ERROR: 'ALIPAY_PAYMENT_ERROR',
  UNIONPAY_PAYMENT_ERROR: 'UNIONPAY_PAYMENT_ERROR',
  CARRIER_AU_PAYMENT_ERROR: 'CARRIER_AU_PAYMENT_ERROR',
  CARRIER_DOCOMO_PAYMENT_ERROR: 'CARRIER_DOCOMO_PAYMENT_ERROR',
  CARRIER_SOFTBANK_PAYMENT_ERROR: 'CARRIER_SOFTBANK_PAYMENT_ERROR',
  PAYMENT_METHOD_NOT_SELECTED: 'PAYMENT_METHOD_NOT_SELECTED',
};

export type ClientErrorCodeKeys = keyof typeof CLIENT_ERROR_CODES;
export type ClientErrorCodes = typeof CLIENT_ERROR_CODES[ClientErrorCodeKeys];

export const CLIENT_ERROR_DESCRIPTIONS: Record<
  ClientErrorCodeKeys,
  ErrorDescription
> = {
  CREDIT_CARD_NOT_SELECTED: {
    title: 'カードが選択されていません',
    description: '登録されているカードまたは新たにカードを登録してください',
  },
  PAYMENT_METHOD_NOT_SELECTED: {
    title: '決済方法が選択されていません',
    description: '決済方法を選択してください',
  },
  UNEXPECTED_ERROR: {
    title: '予期せぬエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  CREDIT_PAYMENT_ERROR: {
    title: 'クレジットカード決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  PAYPAY_PAYMENT_ERROR: {
    title: 'PayPay決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  ALIPAY_PAYMENT_ERROR: {
    title: 'Alipay決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  UNIONPAY_PAYMENT_ERROR: {
    title: 'UnionPay決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  CARRIER_AU_PAYMENT_ERROR: {
    title: 'au決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  CARRIER_DOCOMO_PAYMENT_ERROR: {
    title: 'ドコモ決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
  CARRIER_SOFTBANK_PAYMENT_ERROR: {
    title: 'ソフトバンク決済でエラーが発生しました',
    description: 'お手数ですが、もう一度お試しください',
  },
};
