import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { RootContext, DispatchContext } from '~/store/index';
import {
  DEFAULT_LANGUAGE,
  SupportedLanguage,
  SUPPORTED_LANGUAGES,
} from '~/constants/languages';
import { getLanguageDictionary, getOptions } from '~/src/services/i18n';
import type { Dictionary } from '~/types/components/dictionary';

const initI18next = async (lng) => {
  const i18nInstance = createInstance();
  await i18nInstance.use(initReactI18next).init(getOptions(lng, 'translation'));
  return i18nInstance;
};

export const useTranslation = async (lng, ns, options) => {
  const i18nextInstance = await initI18next(lng);
  return {
    t: i18nextInstance.getFixedT(lng),
    i18n: i18nextInstance,
  };
};

export const useI18nLn = () => {
  const { i18nLn } = useContext(RootContext);
  const dispatch = useContext(DispatchContext);
  const { data, error } = useSWR([i18nLn], getLanguageDictionary, {
    refreshInterval: 0,
  });
  const { data: translation, error: tInstanceError } = useSWR(
    [i18nLn, 'translation'],
    (lng, ns) => useTranslation(lng, ns, { suspense: true })
  );

  useEffect(() => {
    if (i18nLn != null) return;
    const localStorageI18nLn = getLocalStorageI18nLn();
    setI18nLn(localStorageI18nLn);
  }, [i18nLn]);

  const setI18nLn = (ln: SupportedLanguage) => {
    setLocalStorageI18nLn(ln);
    dispatch({
      type: 'SET_I18N_LN',
      payload: {
        i18nLn: ln,
      },
    });
  };

  const setLocalStorageI18nLn = (ln: SupportedLanguage) => {
    localStorage.setItem('i18nLn', ln);
  };

  const getLocalStorageI18nLn = (): SupportedLanguage => {
    const localStorageValue = localStorage.getItem('i18nLn');
    if (
      localStorageValue == null ||
      !SUPPORTED_LANGUAGES.includes(localStorageValue as SupportedLanguage)
    ) {
      return DEFAULT_LANGUAGE;
    }
    return localStorageValue as SupportedLanguage;
  };

  const t = (prefix, obj?: any): string => {
    if (translation == undefined || translation.t == undefined) return '';
    return translation.t(prefix, obj) as string;
  };

  return {
    dictionary: data as Dictionary | null,
    error,
    i18nLn,
    setI18nLn,
    t: t,
  };
};
