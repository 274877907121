import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { postOneTimePassword } from '~/lib/api/post_one_time_password';
import { useI18nLn } from '~/hooks/store';
import { useUser } from '~/hooks/resources';

export const OneTimePasswordFormModal = ({ isOpen, onClose }) => {
  const { t } = useI18nLn();
  const { token } = useUser();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const toast = useToast();

  const onSubmit = async (data) => {
    try {
      const authorization = token?.token;
      const code = data.oneTimePassCode;

      if (authorization === undefined) {
        toast({
          title: t('pages.onepass.please_login'),
          isClosable: true,
          status: 'error',
        });
        return;
      } else {
        const response = await postOneTimePassword(code, authorization);

        if (response.body.checked_gacha_ids.length > 0) {
          toast({
            title: `${response.body.checked_gacha_ids.length}件のガチャが見つかりました`,
            isClosable: true,
            status: 'success',
          });
        } else {
          toast({
            title: '失敗しました',
            isClosable: true,
            status: 'error',
          });
        }
      }
    } catch (error) {
      console.error('エラー', error);
      toast({
        title: t('pages.onepass.send_error'),
        isClosable: true,
        status: 'error',
      });
    }
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('global.header.menus.input_code')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.oneTimePassCode}>
              <Input
                type="password"
                variant="outline"
                placeholder="PassCode"
                {...register('oneTimePassCode', {
                  pattern: {
                    value: /^[a-zA-Z0-9]{6}$/,
                    message: t('pages.onepass.invalid_format'), // 正しい形式で入力してください
                  },
                  required: t('pages.onepass.please_enter'), // コードを入力してください
                })}
              />
              <FormErrorMessage>
                {errors.oneTimePassCode &&
                typeof errors.oneTimePassCode.message === 'string'
                  ? errors.oneTimePassCode.message
                  : null}
              </FormErrorMessage>
            </FormControl>
            <Stack direction="column">
              <Flex justify="right">
                <Box mt="5">
                  <Button type="submit" colorScheme="blue">
                    {t('global.common.submit')}
                  </Button>
                </Box>
              </Flex>
            </Stack>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
