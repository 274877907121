'use client';
import { useContext, useEffect, useCallback } from 'react';
import {
  type Auth,
  type User,
  type IdTokenResult,
  onIdTokenChanged,
} from 'firebase/auth';
import { useAuthState, useIdToken } from 'react-firebase-hooks/auth';

import { RootContext, DispatchContext } from '~/store/index';
import { getAuthInstance } from '~/plugins/firebase';
import {
  setupAnonymousUser,
  initializeArcApiUser,
  handleIdTokenAutoRefresh,
} from '~/src/services/users';
import { useWindow } from '~/hooks/store/window';

type UseUser = {
  user?: User | null;
  loading: boolean;
  error?: Error;
  token?: IdTokenResult;
  mutate: () => void;
  logout: () => Promise<void>;
};

// FIXME: グローバルで一つのタイマーを使い回す形にしないとuseUserそれそれでタイマーが作られる
// 命名を長くして被らないことを前提に定義しておく
let userInitializeThrottleTimer: NodeJS.Timeout | null = null;
const userInitializeThrottle = (func: () => Promise<void>, timeout: number) => {
  return function (...args: any[]) {
    if (!userInitializeThrottleTimer) {
      func.apply(this, args);
      userInitializeThrottleTimer = setTimeout(() => {
        userInitializeThrottleTimer = null;
      }, timeout);
    }
  };
};

export const useUser = (): UseUser => {
  const auth: Auth = getAuthInstance();
  const [authUser, authLoading, authError] = useAuthState(auth);
  const [user, loading] = useIdToken(auth);
  const { token } = useContext(RootContext);
  const dispatch = useContext(DispatchContext);
  const { isWindowVisible } = useWindow();

  // ページ表示時にtokenを取得する
  // これがない場合、タブ非表示状態でトークンの有効期限が切れたときにリフレッシュされない
  useEffect(() => {
    if (!isWindowVisible) return;
    if (user == null) return;
    handleIdTokenAutoRefresh({ user, token });
  }, [isWindowVisible, user, token]);

  // authステートの更新ごとにリスナーを設置しないと無限ループが起きる
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user == null) return;
      const tokenResult = await user.getIdTokenResult(false);
      setIdTokenResult(tokenResult);
    });

    return () => {
      unsubscribe();
    };
  }, [auth]);

  const refreshUser = async () => {
    if (loading) return;
    if (user == null) {
      const anonymousToken = await setupAnonymousUser(auth);
      setIdTokenResult(anonymousToken);
    } else {
      const tokenResult = await user.getIdTokenResult(true);
      await initializeArcApiUser(tokenResult.token);
      setIdTokenResult(tokenResult);
    }
  };
  const throttledRefreshUser = useCallback(
    userInitializeThrottle(refreshUser, 2000),
    [user, loading]
  );

  useEffect(() => {
    if (loading) return;
    throttledRefreshUser();
  }, [user, loading]);

  const refreshToken = async (user: User) => {
    const token_result = await user.getIdTokenResult(true);
    setIdTokenResult(token_result);
  };

  const setIdTokenResult = (result: IdTokenResult) =>
    dispatch({
      type: 'SET_TOKEN',
      payload: {
        token: result,
      },
    });

  const mutate = () => {
    if (user == null) return;
    refreshToken(user);
  };

  const logout = async () => {
    const anonymousToken = await setupAnonymousUser(auth);
    setIdTokenResult(anonymousToken);
  };

  return {
    user: authUser,
    loading: authLoading,
    error: authError,
    token: token ?? undefined,
    mutate,
    logout,
  };
};
