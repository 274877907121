import React from 'react';
import { chakra } from '@chakra-ui/react';
import type { User } from 'firebase/auth';
import { FooterBeforeCaution } from './footer/FooterBeforeCaution';
import { FooterInfo } from './footer/FooterInfo';
import { useScreenCheck } from '~/hooks/resources/screen_check';

type Props = {
  user?: User;
  before_caution?: boolean | null;
  bottom_margin?: number | null;
};

export const Footer: React.FC<Props> = (props: Props) => {
  const screeCheck = useScreenCheck();

  return (
    <chakra.footer
      display="flex"
      flexDir="column"
      w="full"
      zIndex={2}
      top="100vh"
      position="sticky"
    >
      {props.before_caution && (
        <FooterBeforeCaution
          isMobileScreen={screeCheck.isMobileScreen}
          user={props.user}
        />
      )}
      <FooterInfo
        isMobileScreen={screeCheck.isMobileScreen}
        bottom_margin={props.bottom_margin}
      />
    </chakra.footer>
  );
};
