import useAspidaSWR from '@aspida/swr';
import { useEffect } from 'react';
import { useUser } from '.';
import { apiClient } from '~/lib/apiClient';
import type { DeliveryInfo } from '~/types/resources/deliveryInfo';
import { MeInfos } from '~/types/resources/meinfos';
import { isActiveToken } from '~/src/services/users';
import { isArcApiError } from '~/src/services/errors';

type UseDeliveryInfo = {
  me_infos?: MeInfos;
  user_attribute?: DeliveryInfo;
  nickname?: string | null;
  icon_image_url?: string | null;
  canDeiveryInfoEdit: boolean;
  loading: boolean;
  error: Error;
  mutate: () => void;
};

export const useDeliveryInfo = (): UseDeliveryInfo => {
  const { token } = useUser();
  const { data, error, mutate } = useAspidaSWR(apiClient.v1.me.infos, {
    headers: {
      Authorization: `Bearer ${token?.token}`,
    },
    enabled: token != null && isActiveToken(token),
  });

  useEffect(() => {
    mutate();
  }, [token]);

  const isDataError = isArcApiError(data);

  return {
    me_infos: data,
    user_attribute: data?.user_attribute,
    nickname: data?.nickname,
    icon_image_url: data?.icon_image_url,
    canDeiveryInfoEdit: !(data?.user_attribute === null),
    loading: !data && !error,
    error: isDataError ? data : error,
    mutate,
  };
};
