import {
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  ModalHeader,
  Flex,
  useBoolean,
  useToast,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { sendPasswordResetEmail } from 'firebase/auth';
import { getAuthInstance } from '~/plugins/firebase';

export type PassResetForm = {
  email: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const PasswordResetModal: React.FC<Props> = (props: Props) => {
  const toast = useToast();
  const auth = getAuthInstance();
  const [submitting, setSubmitting] = useBoolean();
  const [pass_login_error, setPassLoginError] = useState<string>('');
  const [mail, setMail] = useState<string>('');

  const onSendPassResetMail = async (): Promise<void> => {
    setSubmitting.on();
    try {
      await sendPasswordResetEmail(auth, mail);
      setPassLoginError('');
      props.onClose();
      toast({
        title: 'パスワードリセットメールを送信しました',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (e) {
      switch (e?.code) {
        case 'firebase_auth/invalid-email':
          setPassLoginError('有効なメールアドレスを入力してください。');
          break;
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          setPassLoginError('Emailが間違っています');
          break;
        case 'firebase_auth/too-many-requests':
          setPassLoginError(
            '複数回ログインに失敗しています。時間を置いてログインをお願いします。'
          );
          break;
        case 'firebase_auth/network-request-failed':
          setPassLoginError(
            '通信環境がありません。通信環境を確認してください。'
          );
          break;
        default:
          setPassLoginError('Emailが間違っています');
          break;
      }
    }
    setSubmitting.off();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent mx="32px" my={0} alignSelf="center">
        <ModalHeader pt="8px" pb="0" px="8px">
          <Flex flexDir="row" w="full" justify="right" align="end">
            <Image
              src="/assets/icon/close.svg"
              alt="icon"
              h="28px"
              w="28px"
              onClick={props.onClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex justify="center" w="full">
            <Text fontSize="13px" fontWeight="bold">
              パスワードリセットメールを送信します
            </Text>
          </Flex>
          <Box h="16px" />
          <Text m={0} fontSize="xs">
            メールアドレス
          </Text>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={AiOutlineMail} color="primary" w={5} h={5} />
            </InputLeftElement>
            <Input
              type="email"
              value={mail}
              onChange={(e) => {
                setMail(e.target.value);
              }}
              size="md"
              focusBorderColor="primary"
              variant="flushed"
              placeholder="email"
            />
          </InputGroup>
          <Box h={2} />
          {pass_login_error != '' && (
            <Text width="full" fontSize="xs" textColor="red">
              {pass_login_error}
            </Text>
          )}
          <Flex justifyContent="flex-end" mt={4}>
            <Button
              variant="primary"
              w="full"
              isLoading={submitting}
              onClick={onSendPassResetMail}
            >
              パスワードリセットメールを送る
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
