'use client';
import { useMemo, useEffect } from 'react';
import useAspidaSWR from '@aspida/swr';

import { useI18nLn } from '~/hooks/store';
import { useUser } from '~/hooks/store/user';

import { convertLanguageToISO } from '~/src/services/i18n';
import { apiClient } from '~/lib/apiClient';
import { GachaDetail } from '~/types/resources/gachaDetail';
import { isActiveToken } from '~/src/services/users';
import { isArcApiError } from '~/src/services/errors';

type UseGachaIdInfo = {
  gachaInfo?: GachaDetail;
  loading: boolean;
  error: Error;
  mutate: () => void;
};

export const useGachaIdInfo = (unique_name: string): UseGachaIdInfo => {
  const { i18nLn } = useI18nLn();
  const { token } = useUser();
  const query = useMemo(() => {
    if (i18nLn === 'ja' || i18nLn == null) return {};
    const requestLang = convertLanguageToISO(i18nLn);
    return {
      query: {
        lang: requestLang,
      },
    };
  }, [i18nLn]);

  const { data, error, mutate } = useAspidaSWR(
    apiClient.v1.gachas.by.unique_name._uniqueName(unique_name),
    {
      headers: {
        ...(token?.token != null && { Authorization: `Bearer ${token.token}` }),
      },
      ...query,
      enabled: unique_name != null && token != null && isActiveToken(token),
    }
  );

  useEffect(() => {
    if (unique_name == null) return;
    mutate();
  }, [unique_name, i18nLn, token]);

  const is_fetch_error: boolean = useMemo(() => {
    return isArcApiError(data);
  }, [data]);

  return {
    gachaInfo: data,
    loading: !data && !error,
    error: is_fetch_error ? data : error,
    mutate,
  };
};
