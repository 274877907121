import { useState, useEffect } from 'react';

type UseScrollBorder = {
  isActive: boolean;
};

export const useScrollBorder = (activePoint: number): UseScrollBorder => {
  const [isHeaderActive, setIsHeaderActive] = useState<boolean>(true);

  useEffect(() => {
    const scrollWindow = () => {
      const ap = activePoint;
      let scroll = 0;

      scroll = window.pageYOffset;
      if (ap <= scroll) {
        setIsHeaderActive(false);
      } else {
        setIsHeaderActive(true);
      }
    };
    window.addEventListener('scroll', scrollWindow);
    return () => {
      window.removeEventListener('scroll', scrollWindow);
    };
  }, [activePoint]);

  return { isActive: isHeaderActive };
};
