'use client';
import useAspidaSWR from '@aspida/swr';
import { apiClient } from '~/lib/apiClient';
import { OrderPrize } from '~/types/resources/orderPrize';

type UseGachaIdPrize = {
  gacha_id: string;
  orderPrizes: OrderPrize[];
  loading: boolean;
  error: Error;
  mutate: () => void;
};

export const useGachaIdPrizes = (gacha_id: string): UseGachaIdPrize => {
  const { data, error, mutate } = useAspidaSWR(
    apiClient.v1.gachas._gachaId(gacha_id).prizes
  );

  return {
    gacha_id,
    orderPrizes: data?.prizes ?? [],
    loading: !data && !error,
    error: error,
    mutate,
  };
};
