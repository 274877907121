import React from 'react';
import { Drawer, DrawerOverlay, DrawerContent } from '@chakra-ui/react';

import { PrimaryModalCloseButton } from '~/components/common/modals/PrimaryModalCloseButton';
import type { SigninMethods } from '~/types';
import { LoginBottomDrawerContent } from '~/components/login/LoginBottomDrawerContent';
import { SignupBottomDrawerContent } from '~/components/login/SignupBottomDrawerContent';

type Props = {
  loading: boolean;
  guide_signin: SigninMethods | null;
  setGuideSignin: {
    on: (arg0: SigninMethods) => void;
    off: () => void;
  };
};

export const SigninDrawer: React.FC<Props> = (props: Props) => {
  const onLoggedIn = () => {
    props.setGuideSignin.off();
  };

  const onLoginRedirect = () => {
    props.setGuideSignin.on('login');
  };

  const onSignupRedirect = () => {
    props.setGuideSignin.on('signup');
  };

  return (
    <Drawer
      placement="bottom"
      onClose={onLoggedIn}
      isOpen={props.guide_signin !== null}
      autoFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent roundedTop="lg" maxH={500}>
        <PrimaryModalCloseButton />
        {props.guide_signin === 'signup' ? (
          <SignupBottomDrawerContent
            isOpen={props.guide_signin === 'signup'}
            onClose={onLoggedIn}
            onLoginRedirect={onLoginRedirect}
            loading={props.loading}
          />
        ) : (
          <LoginBottomDrawerContent
            isOpen={props.guide_signin === 'login'}
            onClose={onLoggedIn}
            loading={props.loading}
            onSignupRedirect={onSignupRedirect}
          />
        )}
      </DrawerContent>
    </Drawer>
  );
};
