import type { AspidaClient, BasicHeaders } from 'aspida'
import { dataToURLString } from 'aspida'
import type { Methods as Methods0 } from './v1/gachas'
import type { Methods as Methods1 } from './v1/gachas/_gachaId@string'
import type { Methods as Methods2 } from './v1/gachas/_gachaId@string/delivery_bundles'
import type { Methods as Methods3 } from './v1/gachas/_gachaId@string/orders'
import type { Methods as Methods4 } from './v1/gachas/_gachaId@string/payment_methods'
import type { Methods as Methods5 } from './v1/gachas/_gachaId@string/prize_categories'
import type { Methods as Methods6 } from './v1/gachas/_gachaId@string/prizes'
import type { Methods as Methods7 } from './v1/gachas/by/unique_name/_uniqueName@string'
import type { Methods as Methods8 } from './v1/me'
import type { Methods as Methods9 } from './v1/me/areas'
import type { Methods as Methods10 } from './v1/me/beacons'
import type { Methods as Methods11 } from './v1/me/credits'
import type { Methods as Methods12 } from './v1/me/credits/_cardSeqId@string'
import type { Methods as Methods13 } from './v1/me/granted_prizes'
import type { Methods as Methods14 } from './v1/me/granted_prizes/benefits/_benefitPrizeId@string/download'
import type { Methods as Methods15 } from './v1/me/granted_prizes/prizes/_prizeId@string/download'
import type { Methods as Methods16 } from './v1/me/icons/upload'
import type { Methods as Methods17 } from './v1/me/infos'
import type { Methods as Methods18 } from './v1/me/ocodes'
import type { Methods as Methods19 } from './v1/orders'
import type { Methods as Methods20 } from './v1/orders/_orderId@string'
import type { Methods as Methods21 } from './v1/orders/_orderId@string/payments'
import type { Methods as Methods22 } from './v1/orders/_orderId@string/payments/alipay'
import type { Methods as Methods23 } from './v1/orders/_orderId@string/payments/carriers/au'
import type { Methods as Methods24 } from './v1/orders/_orderId@string/payments/carriers/docomo'
import type { Methods as Methods25 } from './v1/orders/_orderId@string/payments/carriers/softbank'
import type { Methods as Methods26 } from './v1/orders/_orderId@string/payments/credit'
import type { Methods as Methods27 } from './v1/orders/_orderId@string/payments/googlepay'
import type { Methods as Methods28 } from './v1/orders/_orderId@string/payments/unionpay'
import type { Methods as Methods29 } from './v1/pickups'
import type { Methods as Methods30 } from './v1/redeem_tickets'
import type { Methods as Methods31 } from './v1/users/signup'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/v1/gachas'
  const PATH1 = '/delivery_bundles'
  const PATH2 = '/orders'
  const PATH3 = '/payment_methods'
  const PATH4 = '/prize_categories'
  const PATH5 = '/prizes'
  const PATH6 = '/v1/gachas/by/unique_name'
  const PATH7 = '/v1/me'
  const PATH8 = '/v1/me/areas'
  const PATH9 = '/v1/me/beacons'
  const PATH10 = '/v1/me/credits'
  const PATH11 = '/v1/me/granted_prizes'
  const PATH12 = '/v1/me/granted_prizes/benefits'
  const PATH13 = '/download'
  const PATH14 = '/v1/me/granted_prizes/prizes'
  const PATH15 = '/v1/me/icons/upload'
  const PATH16 = '/v1/me/infos'
  const PATH17 = '/v1/me/ocodes'
  const PATH18 = '/v1/orders'
  const PATH19 = '/payments'
  const PATH20 = '/payments/alipay'
  const PATH21 = '/payments/carriers/au'
  const PATH22 = '/payments/carriers/docomo'
  const PATH23 = '/payments/carriers/softbank'
  const PATH24 = '/payments/credit'
  const PATH25 = '/payments/googlepay'
  const PATH26 = '/payments/unionpay'
  const PATH27 = '/v1/pickups'
  const PATH28 = '/v1/redeem_tickets'
  const PATH29 = '/v1/users/signup'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'
  const PATCH = 'PATCH'

  return {
    v1: {
      gachas: {
        _gachaId: (val2: string) => {
          const prefix2 = `${PATH0}/${val2}`

          return {
            delivery_bundles: {
              get: (option: { headers: Methods2['get']['reqHeaders'], config?: T | undefined }) =>
                fetch<Methods2['get']['resBody']>(prefix, `${prefix2}${PATH1}`, GET, option).json(),
              $get: (option: { headers: Methods2['get']['reqHeaders'], config?: T | undefined }) =>
                fetch<Methods2['get']['resBody']>(prefix, `${prefix2}${PATH1}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH1}`
            },
            orders: {
              post: (option: { body: Methods3['post']['reqBody'], headers: Methods3['post']['reqHeaders'], config?: T | undefined }) =>
                fetch<Methods3['post']['resBody']>(prefix, `${prefix2}${PATH2}`, POST, option).json(),
              $post: (option: { body: Methods3['post']['reqBody'], headers: Methods3['post']['reqHeaders'], config?: T | undefined }) =>
                fetch<Methods3['post']['resBody']>(prefix, `${prefix2}${PATH2}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH2}`
            },
            payment_methods: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods4['get']['resBody']>(prefix, `${prefix2}${PATH3}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods4['get']['resBody']>(prefix, `${prefix2}${PATH3}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH3}`
            },
            prize_categories: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods5['get']['resBody']>(prefix, `${prefix2}${PATH4}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods5['get']['resBody']>(prefix, `${prefix2}${PATH4}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH4}`
            },
            prizes: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods6['get']['resBody']>(prefix, `${prefix2}${PATH5}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods6['get']['resBody']>(prefix, `${prefix2}${PATH5}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH5}`
            },
            get: (option?: { query?: Methods1['get']['query'] | undefined, headers?: Methods1['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods1['get']['resBody']>(prefix, prefix2, GET, option).json(),
            $get: (option?: { query?: Methods1['get']['query'] | undefined, headers?: Methods1['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods1['get']['resBody']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods1['get']['query'] } | undefined) =>
              `${prefix}${prefix2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          }
        },
        by: {
          unique_name: {
            _uniqueName: (val4: string) => {
              const prefix4 = `${PATH6}/${val4}`

              return {
                get: (option?: { headers?: Methods7['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods7['get']['resBody']>(prefix, prefix4, GET, option).json(),
                $get: (option?: { headers?: Methods7['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods7['get']['resBody']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`
              }
            }
          }
        },
        get: (option?: { query?: Methods0['get']['query'] | undefined, headers?: Methods0['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json(),
        $get: (option?: { query?: Methods0['get']['query'] | undefined, headers?: Methods0['get']['reqHeaders'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods0['get']['query'] } | undefined) =>
          `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      me: {
        areas: {
          post: (option: { body: Methods9['post']['reqBody'], headers: Methods9['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods9['post']['resBody']>(prefix, PATH8, POST, option).json(),
          $post: (option: { body: Methods9['post']['reqBody'], headers: Methods9['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods9['post']['resBody']>(prefix, PATH8, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH8}`
        },
        beacons: {
          post: (option: { body: Methods10['post']['reqBody'], headers: Methods10['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods10['post']['resBody']>(prefix, PATH9, POST, option).json(),
          $post: (option: { body: Methods10['post']['reqBody'], headers: Methods10['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods10['post']['resBody']>(prefix, PATH9, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH9}`
        },
        credits: {
          _cardSeqId: (val3: string) => {
            const prefix3 = `${PATH10}/${val3}`

            return {
              delete: (option: { headers: Methods12['delete']['reqHeaders'], config?: T | undefined }) =>
                fetch(prefix, prefix3, DELETE, option).send(),
              $delete: (option: { headers: Methods12['delete']['reqHeaders'], config?: T | undefined }) =>
                fetch(prefix, prefix3, DELETE, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          },
          get: (option: { headers: Methods11['get']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods11['get']['resBody']>(prefix, PATH10, GET, option).json(),
          $get: (option: { headers: Methods11['get']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods11['get']['resBody']>(prefix, PATH10, GET, option).json().then(r => r.body),
          post: (option: { body: Methods11['post']['reqBody'], headers: Methods11['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods11['post']['resBody']>(prefix, PATH10, POST, option).json(),
          $post: (option: { body: Methods11['post']['reqBody'], headers: Methods11['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods11['post']['resBody']>(prefix, PATH10, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH10}`
        },
        granted_prizes: {
          benefits: {
            _benefitPrizeId: (val4: string) => {
              const prefix4 = `${PATH12}/${val4}`

              return {
                download: {
                  get: (option: { headers: Methods14['get']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods14['get']['resBody']>(prefix, `${prefix4}${PATH13}`, GET, option).json(),
                  $get: (option: { headers: Methods14['get']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods14['get']['resBody']>(prefix, `${prefix4}${PATH13}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}${PATH13}`
                }
              }
            }
          },
          prizes: {
            _prizeId: (val4: string) => {
              const prefix4 = `${PATH14}/${val4}`

              return {
                download: {
                  get: (option: { headers: Methods15['get']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods15['get']['resBody']>(prefix, `${prefix4}${PATH13}`, GET, option).json(),
                  $get: (option: { headers: Methods15['get']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods15['get']['resBody']>(prefix, `${prefix4}${PATH13}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}${PATH13}`
                }
              }
            }
          },
          get: (option: { query: Methods13['get']['query'], headers: Methods13['get']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods13['get']['resBody']>(prefix, PATH11, GET, option).json(),
          $get: (option: { query: Methods13['get']['query'], headers: Methods13['get']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods13['get']['resBody']>(prefix, PATH11, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods13['get']['query'] } | undefined) =>
            `${prefix}${PATH11}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        icons: {
          upload: {
            post: (option: { body: Methods16['post']['reqBody'], headers: Methods16['post']['reqHeaders'], config?: T | undefined }) =>
              fetch<Methods16['post']['resBody']>(prefix, PATH15, POST, option).json(),
            $post: (option: { body: Methods16['post']['reqBody'], headers: Methods16['post']['reqHeaders'], config?: T | undefined }) =>
              fetch<Methods16['post']['resBody']>(prefix, PATH15, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH15}`
          }
        },
        infos: {
          get: (option: { headers: Methods17['get']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods17['get']['resBody']>(prefix, PATH16, GET, option).json(),
          $get: (option: { headers: Methods17['get']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods17['get']['resBody']>(prefix, PATH16, GET, option).json().then(r => r.body),
          put: (option: { body: Methods17['put']['reqBody'], headers: Methods17['put']['reqHeaders'], config?: T | undefined }) =>
            fetch(prefix, PATH16, PUT, option).send(),
          $put: (option: { body: Methods17['put']['reqBody'], headers: Methods17['put']['reqHeaders'], config?: T | undefined }) =>
            fetch(prefix, PATH16, PUT, option).send().then(r => r.body),
          patch: (option: { body: Methods17['patch']['reqBody'], headers: Methods17['patch']['reqHeaders'], config?: T | undefined }) =>
            fetch(prefix, PATH16, PATCH, option).send(),
          $patch: (option: { body: Methods17['patch']['reqBody'], headers: Methods17['patch']['reqHeaders'], config?: T | undefined }) =>
            fetch(prefix, PATH16, PATCH, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH16}`
        },
        ocodes: {
          post: (option: { body: Methods18['post']['reqBody'], headers: Methods18['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods18['post']['resBody']>(prefix, PATH17, POST, option).json(),
          $post: (option: { body: Methods18['post']['reqBody'], headers: Methods18['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods18['post']['resBody']>(prefix, PATH17, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH17}`
        },
        get: (option: { headers: Methods8['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).json(),
        $get: (option: { headers: Methods8['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH7}`
      },
      orders: {
        _orderId: (val2: string) => {
          const prefix2 = `${PATH18}/${val2}`

          return {
            payments: {
              alipay: {
                post: (option: { body: Methods22['post']['reqBody'], headers: Methods22['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch<Methods22['post']['resBody']>(prefix, `${prefix2}${PATH20}`, POST, option).json(),
                $post: (option: { body: Methods22['post']['reqBody'], headers: Methods22['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch<Methods22['post']['resBody']>(prefix, `${prefix2}${PATH20}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH20}`
              },
              carriers: {
                au: {
                  post: (option: { body: Methods23['post']['reqBody'], headers: Methods23['post']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods23['post']['resBody']>(prefix, `${prefix2}${PATH21}`, POST, option).json(),
                  $post: (option: { body: Methods23['post']['reqBody'], headers: Methods23['post']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods23['post']['resBody']>(prefix, `${prefix2}${PATH21}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix2}${PATH21}`
                },
                docomo: {
                  post: (option: { body: Methods24['post']['reqBody'], headers: Methods24['post']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods24['post']['resBody']>(prefix, `${prefix2}${PATH22}`, POST, option).json(),
                  $post: (option: { body: Methods24['post']['reqBody'], headers: Methods24['post']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods24['post']['resBody']>(prefix, `${prefix2}${PATH22}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix2}${PATH22}`
                },
                softbank: {
                  post: (option: { body: Methods25['post']['reqBody'], headers: Methods25['post']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods25['post']['resBody']>(prefix, `${prefix2}${PATH23}`, POST, option).json(),
                  $post: (option: { body: Methods25['post']['reqBody'], headers: Methods25['post']['reqHeaders'], config?: T | undefined }) =>
                    fetch<Methods25['post']['resBody']>(prefix, `${prefix2}${PATH23}`, POST, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix2}${PATH23}`
                }
              },
              credit: {
                post: (option: { body: Methods26['post']['reqBody'], headers: Methods26['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch(prefix, `${prefix2}${PATH24}`, POST, option).send(),
                $post: (option: { body: Methods26['post']['reqBody'], headers: Methods26['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch(prefix, `${prefix2}${PATH24}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH24}`
              },
              googlepay: {
                post: (option: { body: Methods27['post']['reqBody'], headers: Methods27['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch(prefix, `${prefix2}${PATH25}`, POST, option).send(),
                $post: (option: { body: Methods27['post']['reqBody'], headers: Methods27['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch(prefix, `${prefix2}${PATH25}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH25}`
              },
              unionpay: {
                post: (option: { body: Methods28['post']['reqBody'], headers: Methods28['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch<Methods28['post']['resBody']>(prefix, `${prefix2}${PATH26}`, POST, option).json(),
                $post: (option: { body: Methods28['post']['reqBody'], headers: Methods28['post']['reqHeaders'], config?: T | undefined }) =>
                  fetch<Methods28['post']['resBody']>(prefix, `${prefix2}${PATH26}`, POST, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix2}${PATH26}`
              },
              post: (option: { body: Methods21['post']['reqBody'], headers: Methods21['post']['reqHeaders'], config?: T | undefined }) =>
                fetch<Methods21['post']['resBody']>(prefix, `${prefix2}${PATH19}`, POST, option).json(),
              $post: (option: { body: Methods21['post']['reqBody'], headers: Methods21['post']['reqHeaders'], config?: T | undefined }) =>
                fetch<Methods21['post']['resBody']>(prefix, `${prefix2}${PATH19}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH19}`
            },
            get: (option: { headers: Methods20['get']['reqHeaders'], config?: T | undefined }) =>
              fetch<Methods20['get']['resBody']>(prefix, prefix2, GET, option).json(),
            $get: (option: { headers: Methods20['get']['reqHeaders'], config?: T | undefined }) =>
              fetch<Methods20['get']['resBody']>(prefix, prefix2, GET, option).json().then(r => r.body),
            delete: (option: { headers: Methods20['delete']['reqHeaders'], config?: T | undefined }) =>
              fetch(prefix, prefix2, DELETE, option).send(),
            $delete: (option: { headers: Methods20['delete']['reqHeaders'], config?: T | undefined }) =>
              fetch(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        get: (option: { query?: Methods19['get']['query'] | undefined, headers: Methods19['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods19['get']['resBody']>(prefix, PATH18, GET, option).json(),
        $get: (option: { query?: Methods19['get']['query'] | undefined, headers: Methods19['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods19['get']['resBody']>(prefix, PATH18, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods19['get']['query'] } | undefined) =>
          `${prefix}${PATH18}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      pickups: {
        get: (option: { headers: Methods29['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods29['get']['resBody']>(prefix, PATH27, GET, option).json(),
        $get: (option: { headers: Methods29['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods29['get']['resBody']>(prefix, PATH27, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH27}`
      },
      redeem_tickets: {
        get: (option: { headers: Methods30['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods30['get']['resBody']>(prefix, PATH28, GET, option).json(),
        $get: (option: { headers: Methods30['get']['reqHeaders'], config?: T | undefined }) =>
          fetch<Methods30['get']['resBody']>(prefix, PATH28, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH28}`
      },
      users: {
        signup: {
          post: (option: { headers: Methods31['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods31['post']['resBody']>(prefix, PATH29, POST, option).json(),
          $post: (option: { headers: Methods31['post']['reqHeaders'], config?: T | undefined }) =>
            fetch<Methods31['post']['resBody']>(prefix, PATH29, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH29}`
        }
      }
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
