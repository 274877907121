import { type User, type IdTokenResult } from 'firebase/auth';
import { isRequiredToRefreshToken } from './';

export const handleIdTokenAutoRefresh = async (input: {
  token: IdTokenResult | null;
  user: User;
}) => {
  if (input.token == null) return;
  if (isRequiredToRefreshToken(input.token)) {
    await input.user.getIdTokenResult(true);
  }
};
