import useSWR from 'swr';
import { ArcConfig } from '~/types/resources/arcConfig';

export const useArcConfig = () => {
  const { data, error } = useSWR(
    process.env.NEXT_PUBLIC_PRODUCTION_MODE === 'true'
      ? 'https://3barc-config.s3.ap-northeast-1.amazonaws.com/config.json'
      : 'https://dev-3barc.s3.ap-northeast-1.amazonaws.com/config.json',
    fetcher
  );

  return {
    arcConfig: data as ArcConfig,
    isLoading: !error && !data,
    isError: error,
  };
};

const fetcher = async (url: string) => {
  const res = await fetch(url);
  return res.json();
};
