'use client';
import React, { useState } from 'react';
import {
  Flex,
  Text,
  Icon,
  useToast,
  useBoolean,
  Box,
  Link,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { useRouter } from 'next/router';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  //TwitterAuthProvider,
  //FacebookAuthProvider,
  signInWithRedirect,
} from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc';
//import { FaTwitter, FaFacebook } from 'react-icons/fa';

import { usePageError, useI18nLn } from '~/hooks/store';
import { getAuthInstance } from '~/plugins/firebase';
import { LoginForm, LoginFormData } from '~/components/login/Form';
import type { SigninMethods } from '~/types/context';
import { app_links } from '~/lib/app_links';

type Props = {
  methods: SigninMethods;
};

export const LoginProviders: React.FC<Props> = (props: Props) => {
  const toast = useToast();
  const router = useRouter();
  const methods = useForm<LoginFormData>();
  const { t } = useI18nLn();
  const auth = getAuthInstance();
  const [pass_login_error, setPassLoginError] = useState<string>('');
  const { setPageError } = usePageError();
  const [submitting, setSubmitting] = useBoolean();
  const isIOS = /iPad|iPhone|iPod/.test(window.navigator.platform);

  const onEmailLogin = async (data: LoginFormData): Promise<void> => {
    setSubmitting.on();
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      await router.replace(router.asPath);
      toast({
        title: 'ログインしました',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      localStorage.setItem('modal', 'open');
    } catch (e) {
      switch (e?.code) {
        case 'firebase_auth/invalid-email':
          setPassLoginError('有効なメールアドレスを入力してください。');
          break;
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          setPassLoginError('Emailまたはパスワードが間違っています');
          break;
        case 'firebase_auth/too-many-requests':
          setPassLoginError(
            '複数回ログインに失敗しています。時間を置いてログインをお願いします。'
          );
          break;
        case 'firebase_auth/network-request-failed':
          setPassLoginError(
            '通信環境がありません。通信環境を確認してください。'
          );
          break;
        default:
          setPassLoginError('Emailまたはパスワードが間違っています');
          break;
      }
    }
    setSubmitting.off();
  };

  const onEmailSignup = async (data: LoginFormData): Promise<void> => {
    setSubmitting.on();
    try {
      await createUserWithEmailAndPassword(auth, data.email, data.password);
      await router.replace(router.asPath);

      toast({
        title: 'サインアップしました',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      localStorage.setItem('modal', 'open');
    } catch (e) {
      switch (e?.code) {
        case 'firebase_auth/invalid-email':
          setPassLoginError('有効なメールアドレスを入力してください。');
          break;
        case 'firebase_auth/too-many-requests':
          setPassLoginError(
            'メールを送信済みです。メールを確認して認証を完了してください。'
          );
          break;
        case 'firebase_auth/network-request-failed':
          setPassLoginError(
            '通信環境がありません。通信環境を確認してください。'
          );
          break;
        case 'auth/email-already-in-use':
          setPassLoginError('既に存在するユーザです。ログインをお試しください');
          break;
        default:
          setPassLoginError('Emailが間違っています');
          break;
      }
    }
    setSubmitting.off();
  };

  const onGoogleLogin = async () => {
    try {
      const google_provider = new GoogleAuthProvider();
      signInWithRedirect(auth, google_provider);
      localStorage.setItem('modal', 'open');
    } catch (e) {
      setPageError({
        title: '認証に失敗しました',
        body: '解決しない場合は別の認証方法をご利用ください',
      });
    }
  };

  //const onTwitterLogin = async () => {
  //  try {
  //    const twitter_provider = new TwitterAuthProvider();
  //    signInWithRedirect(auth, twitter_provider);
  //  } catch (e) {
  //    setPageError({
  //      title: '認証に失敗しました',
  //      body: '解決しない場合は別の認証方法をご利用ください',
  //    });
  //  }
  //};

  //const onFacebookLogin = async () => {
  //  try {
  //    const facebook_provider = new FacebookAuthProvider();
  //    facebook_provider.setCustomParameters({ lang: 'jp' });
  //    signInWithRedirect(auth, facebook_provider);
  //  } catch (e) {
  //    setPageError({
  //      title: '認証に失敗しました',
  //      body: '解決しない場合は別の認証方法をご利用ください',
  //    });
  //  }
  //};

  return (
    <Flex flexDir="column" alignItems="center" w="full">
      <Flex flexDir="column" py={2} px={4} bgColor="gray.100" w="full">
        {props.methods === 'login'
          ? t('guide.email_login')
          : t('guide.email_signup')}
      </Flex>
      <Flex my={4} py={2} px={4} flexDir="column" w="full">
        <FormProvider {...methods}>
          <LoginForm
            onSubmit={props.methods === 'login' ? onEmailLogin : onEmailSignup}
            errorMessage={pass_login_error}
            setErrorMessage={setPassLoginError}
            methods={props.methods}
            submitting={submitting}
          />
        </FormProvider>
      </Flex>
      <Flex flexDir="column" py={2} px={4} bgColor="gray.100" w="full">
        {t('guide.login_sns')}
      </Flex>
      <Flex flexDir="column" px={2} w="full" py={2}>
        <Flex
          my={1}
          py={2}
          px={6}
          rounded="lg"
          border={1}
          borderColor="gray.300"
          borderStyle="solid"
          w="full"
          onClick={onGoogleLogin}
          _hover={{ bgColor: 'gray.100' }}
        >
          <Icon as={FcGoogle} w={6} h={6} />
          <Flex flexGrow={1} justifyContent="center">
            <Text mx={2} fontSize="lg" textColor="gray.600">
              Google
            </Text>
          </Flex>
        </Flex>
        {isIOS && (
          <>
            <Box h="8px" />
            <Flex align="right" justify="right">
              <Link
                textDecoration="underline"
                isExternal
                href={app_links.googlelogin_info}
              >
                {t('guide.google_login_info')}
              </Link>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};
