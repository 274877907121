// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const isWindowFocused = () => {
  if (typeof document != undefined && document.hasFocus) {
    return document.hasFocus();
  }
  return true;
};

const isDocumentDefined = typeof document != 'undefined';
const isWindowDefined = typeof window != 'undefined';

const [onWindowEvent, offWindowEvent] =
  isWindowDefined && window.addEventListener
    ? [
        window.addEventListener.bind(window),
        window.removeEventListener.bind(window),
      ]
    : [noop, noop];

export const initFocus = (callback: () => void) => {
  // focus revalidate
  if (isDocumentDefined) {
    document.addEventListener('visibilitychange', callback);
  }
  onWindowEvent('focus', callback);
  return () => {
    if (isDocumentDefined) {
      document.removeEventListener('visibilitychange', callback);
    }
    offWindowEvent('focus', callback);
  };
};
