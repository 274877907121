export const firebaseConfig = {
  apiKey: 'AIzaSyAB2sAi1muFlV-55KT1IEW3BylZbpikmCY',
  authDomain: process.env.NEXT_PUBLIC_PRODUCTION_MODE
    ? 'roll.tokyo'
    : 'dev.roll.tokyo',
  projectId: 'web-roll',
  storageBucket: 'web-roll.appspot.com',
  messagingSenderId: '538603482056',
  appId: '1:538603482056:web:443c18dcd86a1c3697aefd',
  measurementId: 'G-5BE62LDMEH',
};
