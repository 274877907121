import * as React from 'react';
import NextHead from 'next/head';

type Props = {
  title?: string;
  keywords?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
};

const Head: React.FC<Props> = (props: Props) => {
  const title = (props.title ? `${props.title} / ` : '') + 'Web ROLL';
  const description =
    (props.description ? `${props.description} / ` : '') +
    '全国どこでも「ROLL（ロール）」しよう。ROLL（ロール）は、必ず当たる!ハズレなしのオンラインくじサービスです。ココでしか手に入らないオリジナルグッズ・デジタルコンテンツを気軽に購入できます。';
  const keywords =
    (props.keywords ? `${props.keywords},` : '') +
    'WebROLL,Web ROLL,ROLL,オンラインくじ,オンラインガチャ,デジタルガチャ';
  const image = props.image ?? 'https://roll.tokyo/assets/ogp.png';
  const url = `https://roll.tokyo${props.url ?? '/'}`;
  const type = props.type ?? 'article';

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="theme-color" content="#000000" />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/assets/roll-back.png"
      />
      <link
        rel="preconnect"
        href="https://dev-507-assets.s3.ap-northeast-1.amazonaws.com"
      />
      <link rel="preconnect" href="https://apis.google.com" />
      <link rel="preconnect" href="https://www.googleapis.com" />
      {/*OGP*/}
      <head prefix="og: http://ogp.me/ns#" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Web ROLL" />
      <meta property="og:image" content={image} />
    </NextHead>
  );
};

export default Head;
