'use client';
import { useContext, useEffect } from 'react';
import { RootContext, DispatchContext } from '~/store/index';
import { useUser } from '~/hooks/resources';

import type { SigninMethods } from '~/types';

type UseGuideSignin = {
  guide_signin?: SigninMethods;
  setGuideSignin: { on: (arg0: SigninMethods) => void; off: () => void };
};

export const useGuideSignin = (): UseGuideSignin => {
  const { user, loading } = useUser();
  const { guide_signin } = useContext(RootContext);
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    if (user) {
      off();
    }
  }, [user, loading]);

  const on = async (method: SigninMethods) =>
    dispatch({
      type: 'SET_GUIDE_SIGNIN',
      payload: {
        guide_signin: method,
      },
    });

  const off = async () =>
    dispatch({
      type: 'SET_GUIDE_SIGNIN',
      payload: {
        guide_signin: null,
      },
    });

  return {
    guide_signin: guide_signin ?? undefined,
    setGuideSignin: { on, off },
  };
};
