import { extendTheme, defineStyleConfig } from '@chakra-ui/react';

const ButtonStyle = defineStyleConfig({
  baseStyle: {
    _focus: {
      boxShadow: 0,
      textColor: 'white',
    },
  },
  variants: {
    contained: {
      bg: 'blank',
      color: 'link',
      _hover: {
        bg: 'gray.300',
      },
      _active: {
        bg: 'gray.300',
      },
      _focus: {
        bg: 'gray.300',
      },
      _disabled: {
        pointerEvents: 'none',
      },
      _loading: {
        color: 'primary',
        borderWidth: 1,
        borderColor: 'primary',
      },
    },
    primary: {
      bg: 'primary',
      color: 'white',
      _hover: {
        bg: 'main',
        color: 'white',
      },
      _active: {
        bg: 'main',
        color: 'white',
      },
      _focus: {
        bg: 'main',
        color: 'white',
      },
      _disabled: {
        pointerEvents: 'none',
      },
      _loading: {
        color: 'primary',
        borderWidth: 1,
        borderColor: 'primary',
      },
    },
    outline: {
      borderColor: 'primary',
      color: 'primary',
      _hover: {
        color: 'primary',
      },
      _active: {
        color: 'primary',
      },
      _focus: {
        color: 'primary',
      },
      _disabled: {
        pointerEvents: 'none',
      },
    },
    solid: {
      bg: 'gray.800',
      color: 'white',
      _hover: {
        bg: 'gray.800',
        color: 'white',
      },
      _active: {
        bg: 'gray.500',
        color: 'white',
      },
      _focus: {
        bg: 'gray.800',
        color: 'white',
      },
      _disabled: {
        pointerEvents: 'none',
      },
    },
    dangerouse: {
      bg: 'red.600',
      color: 'white',
      _hover: {
        bg: 'red.500',
        color: 'white',
      },
      _disabled: {
        pointerEvents: 'none',
      },
    },
  },
});

export const theme = extendTheme({
  shadows: {
    outline: {
      boxShadow: 0,
    },
  },
  colors: {
    blank: '#F0F1F2',
    border: '#DADBDD',
    disable: '#A1A3A6',
    desc: '#717679',
    main: '#323435',
    redAccent: '#D24643',
    yellowAccent: '#ECB22A',
    link: '#6D9EF6',
    red: {
      50: '#FFE5E5',
      100: '#F0C9C8',
      200: '#E0A0A0',
      300: '#D17472',
      400: '#C44848',
      500: '#D24643',
      600: '#B03232',
      700: '#902020',
      800: '#701010',
      900: '#500000',
    },
    purple: '#9D57C7',
    blue: '#2871F2',
    gradientRed: 'rgba(210, 70, 67,0.68)',
    gradientPurple: 'rgba(157, 87, 199,0.68)',
    gradientBlue: 'rgba(40, 113, 242,0.68)',
    webroll: {
      50: '#f2eaff',
      100: '#d6c1ff',
      200: '#b999ff',
      300: '#9e70ff',
      400: '#8238ff',
      500: '#8859DD',
      600: '#5b29b9',
      700: '#4a2398',
      800: '#3a1d76',
      900: '#2a1755',
    },
    primary: '#4B1ABA',

    google: '#FFFFFF',
    twitter: '#1D9BF0',
    facebook: '#1877F2',
  },
  components: {
    Button: ButtonStyle,
    ModalCloseButton: {
      baseStyle: {
        _focus: {
          boxShadow: 0,
        },
      },
    },
  },
});
