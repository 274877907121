import { useMediaQuery } from '@chakra-ui/react';

type UseScreenCheck = {
  isMobileScreen: boolean;
  isPcScreen: boolean;
};

export const useScreenCheck = (maxWidth?: number): UseScreenCheck => {
  const [isMobileScreen] = useMediaQuery(`(max-width: ${maxWidth ?? 600}px)`, {
    ssr: true,
    fallback: true,
  });

  return {
    isMobileScreen: isMobileScreen,
    isPcScreen: !isMobileScreen,
  };
};
