import React from 'react';
import { Text, Flex, Box, Divider, Image } from '@chakra-ui/react';

type Props = {
  icon: string;
  title: string;
  link: string;
  last?: boolean;
  isMobileScreen: boolean;
};

export const FooterIconLink: React.FC<Props> = (props: Props) => {
  if (props.isMobileScreen) {
    return (
      <>
        <Flex
          flexDir="row"
          align="center"
          px="16px"
          py="12px"
          w="full"
          onClick={() => {
            window.open(props.link, '_blank');
          }}
        >
          <Image src={props.icon} alt="icon" h="16px" w="16px" />
          <Box w="8px" />
          <Flex w="full">
            <Text fontSize="15px" fontWeight="bold">
              {props.title}
            </Text>
          </Flex>
          <Image
            src="/assets/icon/navigate.svg"
            alt="icon"
            h="16px"
            w="16px"
            color="desc"
          />
        </Flex>
        {!(props.last ?? false) && (
          <Flex w="full">
            <Divider color="border" border="1px" />
          </Flex>
        )}
      </>
    );
  } else {
    return (
      <Flex
        flexDir="column"
        align="center"
        justify="center"
        border="1px"
        borderColor="border"
        flex={1}
        rounded="13px"
        _hover={{ filter: 'brightness(50%)', cursor: 'pointer' }}
        onClick={() => {
          window.open(props.link, '_blank');
        }}
      >
        <Box h="24px" />
        <Image src={props.icon} alt="icon" h="56px" w="56px" />
        <Box h="16px" />
        <Text fontSize="13px" fontWeight="bold" mx="4px">
          {props.title}
        </Text>
        <Box h="16px" />
      </Flex>
    );
  }
};
